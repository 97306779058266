const setItem = (key, value) => {
  if (value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
};

const getItem = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (itemStr) {
    return JSON.parse(itemStr);
  }
  return null;
};

const removeItem = (key) => {
  sessionStorage.removeItem(key);
};

const clear = () => {
  sessionStorage.clear();
};

const appdata = {
  setItem,
  getItem,
  removeItem,
  clear,
};

export default appdata;
