import { call, put } from 'redux-saga/effects';

import { creators as PeopleActions } from 'Reducers/Reporting/People';
import ApiPeople from 'Api/ApiPeople';

export function* getPeopleSummary({ payload }) {
  try {
    const response = yield call(ApiPeople.getSummary, payload.selectedYears);
    yield put(PeopleActions.peopleSuccess({ summaryData: response }));
  } catch (e) {
    yield put(PeopleActions.peopleFailure(e));
  }
}

export function* getPeopleDetail({ payload }) {
  try {
    const response = yield call(ApiPeople.getDetail, payload.DimDate);
    yield put(PeopleActions.peopleSuccess({ detailData: response }));
  } catch (e) {
    yield put(PeopleActions.peopleFailure(e));
  }
}
