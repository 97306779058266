import { call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { creators as TaskActions } from 'Reducers/Task';
import DefaultApi from 'Api/DefaultApi';

export function* getTasks() {
  try {
    const response = yield call(DefaultApi.getAllTasks, null);

    if (response.status === 200)
      yield put(TaskActions.taskSuccess({ tasks: response.data }));
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(TaskActions.taskFailure(e));
  }
}
