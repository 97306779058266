import { call, put } from 'redux-saga/effects';

import { creators as ExpensesActions } from 'Reducers/Reporting/Expenses';
import ApiExpenses from 'Api/ApiExpenses';

export function* getExpensesSummary({ payload }) {
  try {
    const response = yield call(ApiExpenses.getSummary, payload.selectedYears);
    yield put(ExpensesActions.expensesSuccess({ summaryData: response }));
  } catch (e) {
    yield put(ExpensesActions.expensesFailure(e));
  }
}

export function* getExpensesDetail({ payload }) {
  try {
    const response = yield call(ApiExpenses.getDetail, payload.selectedYears);
    yield put(ExpensesActions.expensesSuccess({ detailData: response }));
  } catch (e) {
    yield put(ExpensesActions.expensesFailure(e));
  }
}
