import { call, put } from 'redux-saga/effects';

import { creators as KPIActions } from 'Reducers/Reporting/KPI';
import ApiKPI from 'Api/ApiKPI';

export function* getKPISummary({ payload }) {
  try {
    const response = yield call(ApiKPI.getSummary, payload.selectedYears);
    yield put(KPIActions.kpiSuccess({ summaryData: response }));
  } catch (e) {
    yield put(KPIActions.kpiFailure(e));
  }
}
