import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const ApiDefault = () => {
  const getDefaultInfo = async (OrgName) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let filter = { OrgName: OrgName };

    queryParams += '?filter=' + JSON.stringify(filter);

    return await dispatchGetJSONAsync(
      `${API_ROOT}/default/${company_id}/Info${queryParams}`,
    );
  };

  return {
    getDefaultInfo,
  };
};

export default ApiDefault();
