import { call, put } from 'redux-saga/effects';

import { creators as ProjectedCashFlowActions } from 'Reducers/Reporting/ProjectedCashFlow';
import ApiProjectedCashFlow from 'Api/ApiProjectedCashFlow';

export function* getPCFTopData({ payload }) {
  try {
    const response = yield call(
      ApiProjectedCashFlow.getTop,
      payload.selectedYears,
      payload.DateKey,
    );
    yield put(
      ProjectedCashFlowActions.projectedcashflowSuccess({ topData: response }),
    );
  } catch (e) {
    yield put(ProjectedCashFlowActions.projectedcashflowFailure(e));
  }
}

export function* getPCFMiddleData({ payload }) {
  try {
    const response = yield call(
      ApiProjectedCashFlow.getMiddle,
      payload.selectedYears,
      payload.DateKey,
    );
    yield put(
      ProjectedCashFlowActions.projectedcashflowSuccess({
        middleData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectedCashFlowActions.projectedcashflowFailure(e));
  }
}

export function* getPCFBottomData({ payload }) {
  try {
    const response = yield call(
      ApiProjectedCashFlow.getBottom,
      payload.selectedYears,
    );
    yield put(
      ProjectedCashFlowActions.projectedcashflowSuccess({
        bottomData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectedCashFlowActions.projectedcashflowFailure(e));
  }
}
