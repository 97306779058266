import { createActions, handleActions } from 'redux-actions';

const initState = {
  dashboards: null,
  fetching: false,
  error: null,
};

export const creators = createActions({
  DASHBOARD_GET_DASHBOARD_DATA: (payload) => payload,
  DASHBOARD_SUCCESS: (payload) => payload,
  DASHBOARD_FAILURE: (payload) => payload,
});

const getDashboardDataReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    DASHBOARD_GET_DASHBOARD_DATA: getDashboardDataReducer,
    DASHBOARD_SUCCESS: successReducer,
    DASHBOARD_FAILURE: failureReducer,
  },
  initState,
);
