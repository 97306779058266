import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
} from './BaseApi';

async function base_url() {
  const url = `${API_ROOT}`;
  return url;
}

async function login(email, password) {
  const url = `${API_ROOT}/auth/login`;
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    let res = await response.json();
    return res;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

async function logout() {
  const url = `${API_ROOT}/auth/logout`;
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let res = await response.json();
    return res;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

async function checkSsoUser(email) {
  const url = `${API_ROOT}/auth/sso_check`;
  try {
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    let res = await response.json();
    return res;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

async function getSubscriptionStatus() {
  const user_id = appdata.getItem('user').id;
  const company_id = appdata.getItem('company').id;
  const url = `${API_ROOT}/auth/get_subscription`;
  return await dispatchPostJSONAsync(url, {
    user_id: user_id,
    company_id: company_id,
  });
}

async function signup(
  company,
  email,
  password,
  firstName,
  lastName,
  packageId,
  userCurrency,
  customUsers,
  cardId,
  referralCode,
  basicCount,
  advancedCount,
) {
  const url = `${API_ROOT}/auth/register`;
  return await dispatchPostJSONAsync(url, {
    company: company,
    email: email,
    password: password,
    first_name: firstName,
    last_name: lastName,
    package_id: packageId,
    user_currency: userCurrency,
    have_users: customUsers,
    card_id: cardId,
    referral_code: referralCode,
    basic_count: basicCount,
    advanced_count: advancedCount,
  });
}

async function resetSubscription(
  email,
  packageId,
  userCurrency,
  customUsers,
  cardId,
) {
  const url = `${API_ROOT}/auth/reset_subscription`;
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  return await dispatchPostJSONAsync(url, {
    company_id: company.id,
    user_id: user.id,
    email: email,
    package_id: packageId,
    user_currency: userCurrency,
    have_users: customUsers,
    card_id: cardId,
  });
}

async function getUserCompanies(email, password) {
  const url = `${API_ROOT}/auth/check_user`;
  return await dispatchPostJSONAsync(url, { email, password });
}

async function sendResetPasswordEmail(email) {
  const url = `${API_ROOT}/auth/reset_password_email`;
  return await dispatchPostJSONAsync(url, {
    email,
  });
}

async function resetPassword(token, password) {
  const url = `${API_ROOT}/auth/reset_password`;
  return await dispatchPostJSONAsync(url, {
    token,
    password,
  });
}

async function invite(email, employee_id, role_id, add_billing) {
  const company = appdata.getItem('company');
  const company_org_id = appdata.getItem('company_org_id');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/invite/invite`;
  return await dispatchPostJSONAsync(url, {
    email,
    employee_id,
    company_id: company.id,
    company_org_id,
    user_id: user.id,
    role_id,
    add_billing,
  });
}

async function getInviteInfo(token) {
  const url = `${API_ROOT}/invite/info/token/${token}`;
  return await dispatchGetJSONAsync(url);
}

async function signupWithInvite(
  company_id,
  email,
  employee_id,
  role_id,
  password,
  first_name,
  last_name,
) {
  const url = `${API_ROOT}/invite/register`;
  return await dispatchPostJSONAsync(url, {
    company_id,
    email,
    employee_id,
    password,
    first_name,
    last_name,
    role_id,
  });
}

async function getPeoplelayoutData() {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/peopledesign/${company.id}/${page_id}`;
  return await dispatchGetJSONAsync(url);
}

async function savePeopleLayoutData(userlayout) {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/peopledesign/save/${company.id}/${page_id}`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
  });
}

async function addPeopleLayoutData(userlayout, pageName = '') {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/peopledesign/${company.id}/${page_id}/add`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
    pageName,
  });
}

async function getProjectlayoutData() {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/projectdesign/${company.id}/${page_id}`;
  return await dispatchGetJSONAsync(url);
}

async function saveProjectLayoutData(userlayout) {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/projectdesign/save/${company.id}/${page_id}`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
  });
}

async function addProjectLayoutData(userlayout, pageName = '') {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/projectdesign/${company.id}/${page_id}/add`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
    pageName,
  });
}

async function getCompanylayoutData() {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/companydesign/${company.id}/${page_id}`;
  return await dispatchGetJSONAsync(url);
}

async function saveCompanyLayoutData(userlayout) {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/companydesign/save/${company.id}/${page_id}`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
  });
}

async function addCompanyLayoutData(userlayout, pageName = '') {
  const company = appdata.getItem('company');
  const page_id = appdata.getItem('page_id');
  const url = `${API_ROOT}/companydesign/${company.id}/${page_id}/add`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
    pageName,
  });
}

async function getUserlayoutData() {
  const company = appdata.getItem('company');
  // const user = appdata.getItem('user')
  const page_id = appdata.getItem('page_id');
  // const company_org_id = appdata.getItem('company_org_id')
  const url = `${API_ROOT}/layoutdesign/${company.id}/${page_id}`;
  let res = await dispatchGetJSONAsync(url);
  return JSON.parse(res.userlayout);
}

async function saveUserLayoutData(userlayout) {
  const company = appdata.getItem('company');
  // const user = appdata.getItem('user')
  const page_id = appdata.getItem('page_id');
  // const company_org_id = appdata.getItem('company_org_id')
  const url = `${API_ROOT}/layoutdesign/save/${company.id}/${page_id}`;
  return await dispatchPostJSONAsync(url, {
    userlayout: JSON.stringify(userlayout),
  });
}

//Tasks
async function getAllTasks() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/tasks/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function addTask(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/tasks/${company.id}/add`;
  return await dispatchPostJSONAsync(url, data);
}

async function deleteTask(task_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/tasks/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    task_id,
  });
}

async function updateTask(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/tasks/${company.id}/update`;
  return await dispatchPostJSONAsync(url, data);
}

// page setting
async function getAllSettingPage() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const company_org_id = appdata.getItem('company_org_id');
  const url = `${API_ROOT}/settingpage/${company.id}/${user.id}`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
  });
}

async function getPageLayout() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const company_org_id = appdata.getItem('company_org_id');
  const page_id = appdata.getItem('pageid');
  const url = `${API_ROOT}/settingpage/${company.id}/${user.id}/get_page_layout`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
    page_id,
  });
}

async function addSettingPage() {
  const company = appdata.getItem('company');
  const company_org_id = appdata.getItem('company_org_id');
  const url = `${API_ROOT}/settingpage/${company.id}/add`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
  });
}

async function updateSettingPageData(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settingpage/${company.id}/update/data`;
  return await dispatchPostJSONAsync(url, updated_data);
}

async function deleteSettingPage(setting_page_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/settingpage/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    setting_page_id,
  });
}

// page record
async function getPageRecords() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const company_org_id = appdata.getItem('company_org_id');
  const page_id = appdata.getItem('pageid');
  const url = `${API_ROOT}/custompages/${company.id}/${user.id}`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
    page_id,
  });
}

async function getRecordData() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const recordId = appdata.getItem('recordid');
  const url = `${API_ROOT}/custompages/${company.id}/${user.id}/get_record_data`;
  return await dispatchPostJSONAsync(url, {
    recordId,
  });
}

async function addRecord() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const company_org_id = appdata.getItem('company_org_id');
  const page_id = appdata.getItem('pageid');
  const url = `${API_ROOT}/custompages/${company.id}/${user.id}/add`;
  return await dispatchPostJSONAsync(url, {
    company_org_id,
    page_id,
  });
}

async function updateRecordData(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/custompages/${company.id}/update/data`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

async function updateRecordOneData(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/custompages/${company.id}/oneupdate/data`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

async function updatefieldData(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/custompages/${company.id}/updatefielddata/data`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

async function deleteRecord(record_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/custompages/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    record_id,
  });
}

async function uploadCustomFieldPhoto(photoFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(photoFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const user = appdata.getItem('user');
    const url = `${API_ROOT}/custompages/${company.id}/${user.id}/upload_field_photo`;
    let res = await dispatchPostJSONAsync(url, {
      user_id: user.id,
      photo_file: reader.result.split(',')[1],
    });
    complete(res.photo_url);
  };
}

//Custom Page Layout
async function saveCustomLayout() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const pageId = appdata.getItem('page_id');
  const layout = appdata.getItem('layout');
  const url = `${API_ROOT}/settingpage/${company.id}/${user.id}/savelayout`;
  return await dispatchPostJSONAsync(url, { pageId, layout });
}

async function saveCustomPageRecordData() {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const pageId = appdata.getItem('page_id');
  // const recordId = appdata.getItem('record_id')
  // const recordData = appdata.getItem('record_data')
  const changeState = appdata.getItem('change_state');
  const url = `${API_ROOT}/custompages/${company.id}/${user.id}/saverecorddata`;
  return await dispatchPostJSONAsync(url, {
    pageId,
    changeState,
  });
}

async function getPymentState(user_id, company_id) {
  const url = `${API_ROOT}/auth/get_payment_state`;
  return await dispatchPostJSONAsync(url, {
    user_id,
    company_id,
  });
}

// Payment with Stripe
async function updatePackage(updatePackageId, newPlanID, customUsers) {
  const user_id = appdata.getItem('user').id;
  const company_id = appdata.getItem('company').id;
  const url = `${API_ROOT}/auth/update_package`;
  return await dispatchPostJSONAsync(url, {
    user_id,
    company_id,
    updatePackageId,
    newPlanID,
    customUsers,
  });
}

async function cancelSubscription() {
  const company_id = appdata.getItem('company').id;
  const url = `${API_ROOT}/auth/cancel_subscription`;
  return await dispatchPostJSONAsync(url, {
    company_id,
  });
}

async function updateCC(newCardId, newTokId) {
  const user_id = appdata.getItem('user').id;
  const company_id = appdata.getItem('company').id;
  const url = `${API_ROOT}/auth/update_card_details`;
  return await dispatchPostJSONAsync(url, {
    user_id,
    company_id,
    newCardId,
    newTokId,
  });
}

async function clickConfirmNoti(payload) {
  const url = `${API_ROOT}/admin/click_confirm_noti`;
  return await dispatchPostJSONAsync(url, payload);
}

async function checkReferralCode(payload) {
  const url = `${API_ROOT}/admin/check_referral_code`;
  return await dispatchPostJSONAsync(url, payload);
}

//satisfication rating

async function saveRating(payload) {
  const url = `${API_ROOT}/admin/save_rating`;
  return await dispatchPostJSONAsync(url, payload);
}

//satisfication rating

//dashboard security

async function updateDashboardAccess(payload) {
  const url = `${API_ROOT}/admin/update_dashboard_access`;
  return await dispatchPostJSONAsync(url, {
    ...payload,
  });
}

async function updateRoleAccess(payload) {
  const url = `${API_ROOT}/admin/role/update_access`;
  return await dispatchPostJSONAsync(url, payload);
}

async function updateTimeAccess(payload) {
  const url = `${API_ROOT}/admin/update_time_access`;
  return await dispatchPostJSONAsync(url, {
    ...payload,
  });
}

const DefaultApi = {
  base_url,
  login,
  logout,
  checkSsoUser,
  getSubscriptionStatus,
  signup,
  getUserCompanies,
  sendResetPasswordEmail,
  resetPassword,
  invite,
  getInviteInfo,
  signupWithInvite,
  getUserlayoutData,
  saveUserLayoutData,
  getPymentState,

  getPeoplelayoutData,
  savePeopleLayoutData,
  addPeopleLayoutData,

  getProjectlayoutData,
  saveProjectLayoutData,
  addProjectLayoutData,

  getAllTasks,
  addTask,
  deleteTask,
  updateTask,

  getAllSettingPage,
  getPageLayout,
  addSettingPage,
  updateSettingPageData,
  deleteSettingPage,

  getPageRecords,
  getRecordData,
  addRecord,
  deleteRecord,
  updateRecordData,
  updateRecordOneData,
  updatefieldData,
  uploadCustomFieldPhoto,

  saveCustomLayout,
  saveCustomPageRecordData,

  updatePackage,
  updateCC,
  cancelSubscription,
  resetSubscription,

  getCompanylayoutData,
  saveCompanyLayoutData,
  addCompanyLayoutData,

  checkReferralCode,

  //notification modal
  clickConfirmNoti,
  //notification modal

  //satisfication rating
  saveRating,
  //satisfication rating
  updateDashboardAccess,
  updateTimeAccess,
  updateRoleAccess,
};

export default DefaultApi;
