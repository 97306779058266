import { call, put } from 'redux-saga/effects';
import { creators as CompanySettingsActions } from 'Reducers/CompanySettings';
import { toast } from 'react-toastify';

import SettingApi from 'Api/SettingApi';

export function* getAllCompanySettings() {
  try {
    const response = yield call(SettingApi.getAllCompanySettings, null);
    if (response.status === 200)
      yield put(
        CompanySettingsActions.companySettingsSuccess({
          company_settings: response.data,
        }),
      );
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(CompanySettingsActions.companySettingsFailure(e));
  }
}

export function* getAllOrganisationSettings() {
  try {
    const response = yield call(SettingApi.getAllOrganisationSettings, null);

    if (response.status === 200)
      yield put(
        CompanySettingsActions.companySettingsSuccess({
          organisation_settings: response.data,
        }),
      );
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(CompanySettingsActions.companySettingsFailure(e));
  }
}
