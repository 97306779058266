import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TawkTo from 'tawkto-react';

import PublicLayout from './Public';

class Layout extends Component {
  static displayName = 'Layout';
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    new TawkTo("628f157b7b967b1179914b80", "1g3venrou")
  }

  componentWillUnmount() {}

  render() {
    const { children, ...props } = this.props;
    return (
    <PublicLayout {...props}>
      {children}
    </PublicLayout>
    );
  }
}

export default Layout;
