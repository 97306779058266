import { Client } from '@microsoft/microsoft-graph-client';

let graphClient = undefined;

function ensureClient(authProvider) {
  if (!graphClient) {
    graphClient = Client.initWithMiddleware({
      authProvider: authProvider,
    });
  }

  return graphClient;
}

export async function getUser(authProvider) {
  ensureClient(authProvider);

  const user = await graphClient
    .api('/me')
    .select('displayName,mail,mailboxSettings,userPrincipalName,messages')
    .get();

  return user;
}

export async function getEmails() {
  const emails = await graphClient.api('/me/mailFolders/inbox/messages').get();
  return emails;
}
