import { call, put } from 'redux-saga/effects';

import { creators as FeesProjectionActions } from 'Reducers/Reporting/FeesProjection';
import ApiFeesProjection from 'Api/ApiFeesProjection';

export function* getFeesProjectionSummary({ payload }) {
  try {
    const response = yield call(
      ApiFeesProjection.getSummary,
      payload.selectedYears,
    );
    yield put(
      FeesProjectionActions.feesprojectionSuccess({ summaryData: response }),
    );
  } catch (e) {
    yield put(FeesProjectionActions.feesprojectionFailure(e));
  }
}

export function* getFeesProjectionDetail({ payload }) {
  try {
    const response = yield call(
      ApiFeesProjection.getDetail,
      payload.selectedYears,
    );
    yield put(
      FeesProjectionActions.feesprojectionSuccess({ detailData: response }),
    );
  } catch (e) {
    yield put(FeesProjectionActions.feesprojectionFailure(e));
  }
}
