import { createActions, handleActions } from 'redux-actions';

const initState = {
  peoplePhotos: localStorage.getItem('peoplePhotos')
    ? JSON.parse(localStorage.getItem('peoplePhotos'))
    : [],
  clientPhotos: [],
  projectPhotos: [],
  expensePhotos: [],
  leavePhotos: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  GET_PEOPLE_PHOTOS: (payload) => payload,
  // GET_INVOICE_PDF: (payload) => payload,
  // GET_INVOICE_PDF_SUCCESS: (payload) => payload,
  ADD_PEOPLE_PHOTO: (payload) => payload,
  UPLOAD_PEOPLE_PHOTO: (payload) => payload,
  GET_PEOPLE_PHOTO_SUCCESS: (payload) => payload,
  ADD_PEOPLE_PHOTO_SUCCESS: (payload) => payload,
  UPLOAD_PEOPLE_PHOTO_SUCCESS: (payload) => payload,
  GET_CLIENT_PHOTOS: (payload) => payload,
  ADD_CLIENT_PHOTO: (payload) => payload,
  UPLOAD_CLIENT_PHOTO: (payload) => payload,
  GET_CLIENT_PHOTO_SUCCESS: (payload) => payload,
  ADD_CLIENT_PHOTO_SUCCESS: (payload) => payload,
  UPLOAD_CLIENT_PHOTO_SUCCESS: (payload) => payload,
  GET_PROJECT_PHOTOS: (payload) => payload,
  ADD_PROJECT_PHOTO: (payload) => payload,
  UPLOAD_PROJECT_PHOTO: (payload) => payload,
  GET_PROJECT_PHOTO_SUCCESS: (payload) => payload,
  ADD_PROJECT_PHOTO_SUCCESS: (payload) => payload,
  UPLOAD_PROJECT_PHOTO_SUCCESS: (payload) => payload,
  GET_EXPENSE_PHOTOS: (payload) => payload,
  GET_LEAVE_PHOTOS: (payload) => payload,
  ADDLEAVE_PHOTO: (payload) => payload,
  UPLOAD_LEAVE_PHOTO: (payload) => payload,
  GET_LEAVE_PHOTO_SUCCESS: (payload) => payload,
  ADD_LEAVE_PHOTO_SUCCESS: (payload) => payload,
  UPLOAD_LEAVE_PHOTO_SUCCESS: (payload) => payload,
  ADD_EXPENSE_PHOTO: (payload) => payload,
  UPLOAD_EXPENSE_PHOTO: (payload) => payload,
  GET_EXPENSE_PHOTO_SUCCESS: (payload) => payload,
  ADD_EXPENSE_PHOTO_SUCCESS: (payload) => payload,
  UPLOAD_EXPENSE_PHOTO_SUCCESS: (payload) => payload,
  PHOTO_FAILURE: (payload) => payload,
});

const getPeoplePhotosReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

// const getInvoicePDFReducer = (state) => {
//   return { ...state, fetching: true, error: null };
// }

// const getInvoicePDFReducerSuccess = (state, { payload }) => {
//   let peoplePhotos = state.peoplePhotos;
//   if (peoplePhotos.find((photo) => photo.id === payload.id)) {
//     peoplePhotos.map((photo) =>
//       photo.id === payload.id ? (photo.photo = payload.photo) : photo,
//     );
//   } else peoplePhotos.push(payload);

//   const nextState = {
//     ...state,
//     peoplePhotos,
//     fetching: false,
//     error: null,
//   };
//   return nextState;
// };

const addPeoplePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const uploadPeoplePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getPeoplePhotoSuccessReducer = (state, { payload }) => {
  let peoplePhotos = state.peoplePhotos;
  if (peoplePhotos.find((photo) => photo.id === payload.id)) {
    peoplePhotos.map((photo) =>
      photo.id === payload.id ? (photo.photo = payload.photo) : photo,
    );
  } else peoplePhotos.push(payload);

  const nextState = {
    ...state,
    peoplePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addPeoplePhotoSuccessReducer = (state, { payload }) => {
  const nextState = {
    ...state,
    peoplePhotos: [...state.peoplePhotos, payload],
    fetching: false,
    error: null,
  };
  return nextState;
};

const uploadPeoplePhotoSuccessReducer = (state, { payload }) => {
  let peoplePhotos = state.peoplePhotos;
  peoplePhotos.map((photo) =>
    photo.id === payload.id ? (photo.photo = payload.photo) : photo,
  );

  const nextState = {
    ...state,
    peoplePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const getClientPhotosReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const addClientPhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const uploadClientPhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getClientPhotoSuccessReducer = (state, { payload }) => {
  let clientPhotos = state.clientPhotos;
  if (clientPhotos.find((photo) => photo.id === payload.id)) {
    clientPhotos.map((photo) =>
      photo.id === payload.id ? (photo.photo = payload.photo) : photo,
    );
  } else clientPhotos.push(payload);

  const nextState = {
    ...state,
    clientPhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addClientPhotoSuccessReducer = (state, { payload }) => {
  const nextState = {
    ...state,
    clientPhotos: [...state.clientPhotos, payload],
    fetching: false,
    error: null,
  };
  return nextState;
};

const uploadClientPhotoSuccessReducer = (state, { payload }) => {
  let clientPhotos = state.clientPhotos;
  clientPhotos.map((photo) =>
    photo.id === payload.id ? (photo.photo = payload.photo) : photo,
  );

  const nextState = {
    ...state,
    clientPhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const getProjectPhotosReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const addProjectPhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const uploadProjectPhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getProjectPhotoSuccessReducer = (state, { payload }) => {
  let projectPhotos = state.projectPhotos;
  if (projectPhotos.find((photo) => photo.id === payload.id)) {
    projectPhotos.map((photo) =>
      photo.id === payload.id ? (photo.photo = payload.photo) : photo,
    );
  } else projectPhotos.push(payload);

  const nextState = {
    ...state,
    projectPhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addProjectPhotoSuccessReducer = (state, { payload }) => {
  const nextState = {
    ...state,
    projectPhotos: [...state.projectPhotos, payload],
    fetching: false,
    error: null,
  };
  return nextState;
};

const uploadProjectPhotoSuccessReducer = (state, { payload }) => {
  let projectPhotos = state.projectPhotos;
  projectPhotos.map((photo) =>
    photo.id === payload.id && photo.Type === payload.Type
      ? (photo.photo = payload.photo)
      : photo,
  );

  const nextState = {
    ...state,
    projectPhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const getExpensePhotosReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getLeavePhotosReducer = (state) => {
  return { ...state, fetching: true, error: null };
};
const addLeavePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
}
const uploadLeavePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getLeavePhotoSuccessReducer = (state, { payload }) => {
  let leavePhotos = state.leavePhotos;
  if (leavePhotos.find((photo) => photo.id === payload.id)) {
    leavePhotos.map((photo) =>
      photo.id === payload.id ? (photo.photo = payload.photo) : photo,
    );
  } else leavePhotos.push(payload);

  const nextState = {
    ...state,
    leavePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addLeavePhotoSuccessReducer = (state, { payload }) => {
  const nextState = {
    ...state,
    leavePhotos: [...state.leavePhotos, payload],
    fetching: false,
    error: null,
  };
  return nextState;
};

const uploadLeavePhotoSuccessReducer = (state, { payload }) => {
  let leavePhotos = state.leavePhotos;
  leavePhotos.map((photo) =>
    photo.id === payload.id ? (photo.photo = payload.photo) : photo,
  );

  const nextState = {
    ...state,
    leavePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addExpensePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const uploadExpensePhotoReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getExpensePhotoSuccessReducer = (state, { payload }) => {
  let expensePhotos = state.expensePhotos;
  if (expensePhotos.find((photo) => photo.id === payload.id)) {
    expensePhotos.map((photo) =>
      photo.id === payload.id ? (photo.photo = payload.photo) : photo,
    );
  } else expensePhotos.push(payload);

  const nextState = {
    ...state,
    expensePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const addExpensePhotoSuccessReducer = (state, { payload }) => {
  const nextState = {
    ...state,
    expensePhotos: [...state.expensePhotos, payload],
    fetching: false,
    error: null,
  };
  return nextState;
};

const uploadExpensePhotoSuccessReducer = (state, { payload }) => {
  let expensePhotos = state.expensePhotos;
  expensePhotos.map((photo) =>
    photo.id === payload.id ? (photo.photo = payload.photo) : photo,
  );

  const nextState = {
    ...state,
    expensePhotos,
    fetching: false,
    error: null,
  };
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    GET_PEOPLE_PHOTOS: getPeoplePhotosReducer,
    // GET_INVOICE_PDF: getInvoicePDFReducer,
    ADD_PEOPLE_PHOTO: addPeoplePhotoReducer,
    UPLOAD_PEOPLE_PHOTO: uploadPeoplePhotoReducer,
    // GET_INVOICE_PDF_SUCCESS: getInvoicePDFReducerSuccess,
    GET_PEOPLE_PHOTO_SUCCESS: getPeoplePhotoSuccessReducer,
    ADD_PEOPLE_PHOTO_SUCCESS: addPeoplePhotoSuccessReducer,
    UPLOAD_PEOPLE_PHOTO_SUCCESS: uploadPeoplePhotoSuccessReducer,
    GET_CLIENT_PHOTOS: getClientPhotosReducer,
    ADD_CLIENT_PHOTO: addClientPhotoReducer,
    UPLOAD_CLIENT_PHOTO: uploadClientPhotoReducer,
    GET_CLIENT_PHOTO_SUCCESS: getClientPhotoSuccessReducer,
    ADD_CLIENT_PHOTO_SUCCESS: addClientPhotoSuccessReducer,
    UPLOAD_CLIENT_PHOTO_SUCCESS: uploadClientPhotoSuccessReducer,
    GET_PROJECT_PHOTOS: getProjectPhotosReducer,
    ADD_PROJECT_PHOTO: addProjectPhotoReducer,
    UPLOAD_PROJECT_PHOTO: uploadProjectPhotoReducer,
    GET_PROJECT_PHOTO_SUCCESS: getProjectPhotoSuccessReducer,
    ADD_PROJECT_PHOTO_SUCCESS: addProjectPhotoSuccessReducer,
    UPLOAD_PROJECT_PHOTO_SUCCESS: uploadProjectPhotoSuccessReducer,
    GET_EXPENSE_PHOTOS: getExpensePhotosReducer,
    ADD_EXPENSE_PHOTO: addExpensePhotoReducer,
    UPLOAD_EXPENSE_PHOTO: uploadExpensePhotoReducer,
    GET_EXPENSE_PHOTO_SUCCESS: getExpensePhotoSuccessReducer,
    ADD_EXPENSE_PHOTO_SUCCESS: addExpensePhotoSuccessReducer,
    UPLOAD_EXPENSE_PHOTO_SUCCESS: uploadExpensePhotoSuccessReducer,
    GET_LEAVE_PHOTOS: getLeavePhotosReducer,
    ADDLEAVE_PHOTO: addLeavePhotoReducer,
    UPLOAD_LEAVE_PHOTO: uploadLeavePhotoReducer,
    GET_LEAVE_PHOTO_SUCCESS: getLeavePhotoSuccessReducer,
    ADD_LEAVE_PHOTO_SUCCESS: addLeavePhotoSuccessReducer,
    UPLOAD_LEAVE_PHOTO_SUCCESS: uploadLeavePhotoSuccessReducer,
    PHOTO_FAILURE: failureReducer,
  },
  initState,
);
