import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
} from './BaseApi';

async function getAllTemplates() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getTemplate(template_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/get/${template_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addTemplate(add_template_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/add`;
  return await dispatchPostJSONAsync(url, add_template_data);
}

async function deleteTemplate(template_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    template_id,
  });
}

async function updateTemplate(update_template_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/update`;
  return await dispatchPostJSONAsync(url, update_template_data);
}

async function updateTemplateContent(
  template_id,
  template_setting,
  template_property,
  currency_name,
  currency_symbol,
  thousand_format,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/update_content`;
  return await dispatchPostJSONAsync(url, {
    template_id,
    template_setting,
    template_property,
    currency_name,
    currency_symbol,
    thousand_format,
  });
}

async function uploadTemplate(template_id, docFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(docFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const url = `${API_ROOT}/templates/${company.id}/upload_template`;
    let res = await dispatchPostJSONAsync(url, {
      template_id,
      template_doc_file: reader.result.split(',')[1],
    });
    complete(res);
  };
}

async function createProjectDoc(
  project_id,
  project_type,
  template_id,
  project_number,
  project_name,
  invoice_number,
  people_name,
  companies_name,
  address_1,
  address_2,
  city,
  state,
  country,
  site_address1,
  site_address2,
  site_city,
  site_state,
  site_country,
  people_email,
  project_notes,
  tasks,
  note_indexes,
  rates,
  linked_projects,
  resources,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/project`;

  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    template_id,
    project_number,
    project_name,
    invoice_number,
    people_name,
    companies_name,
    address_1,
    address_2,
    city,
    state,
    country,
    site_address1,
    site_address2,
    site_city,
    site_state,
    site_country,
    people_email,
    project_notes,
    tasks,
    note_indexes,
    rates,
    linked_projects,
    resources,
  });
}

async function createInvoiceDoc(
  project_id,
  template_id,
  template_data,
  grid_columns,
  invoice_id,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/invoice`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    template_id,
    template_data,
    grid_columns,
    invoice_id,
  });
}

async function createCreditInvoiceDoc(
  project_id,
  template_id,
  template_data,
  grid_columns,
  invoice_id,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/credit`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    template_id,
    template_data,
    grid_columns,
    invoice_id,
  });
}

async function createAutoInvoiceDoc(auto_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/auto_invoice`;
  return await dispatchPostJSONAsync(url, auto_data);
}

async function createQuoteDoc(
  orderquote_id,
  template_id,
  template_data,
  grid_columns,
  template_type,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/create/quote`;
  return await dispatchPostJSONAsync(url, {
    orderquote_id,
    template_id,
    template_data,
    grid_columns,
    template_type,
  });
}

async function updateTemplateSetting(
  invoiceSetting,
  invoiceProperty,
  projectSetting,
  projectProperty,
  orderSetting,
  orderProperty,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/templates/${company.id}/setting/update`;
  return await dispatchPostJSONAsync(url, {
    invoiceSetting,
    invoiceProperty,
    projectSetting,
    projectProperty,
    orderSetting,
    orderProperty,
  });
}

const TemplateApi = {
  getAllTemplates,
  getTemplate,
  addTemplate,
  deleteTemplate,
  updateTemplate,
  updateTemplateContent,
  uploadTemplate,
  createProjectDoc,
  createInvoiceDoc,
  createCreditInvoiceDoc,
  createAutoInvoiceDoc,
  createQuoteDoc,
  updateTemplateSetting,
};

export default TemplateApi;
