import { call, put } from 'redux-saga/effects';

import { creators as SummaryActions } from 'Reducers/Reporting/Summary';
import ApiSummary from 'Api/ApiSummary';

export function* getSummary({ payload }) {
  try {
    const response = yield call(ApiSummary.getSummary, payload.dimdate);
    yield put(SummaryActions.summarySuccess({ summary: response }));
  } catch (e) {
    yield put(SummaryActions.summaryFailure(e));
  }
}

export function* setSummary({ payload }) {
  try {
    // const response = yield call(
    //   ApiSummary.setSummary,
    //   payload.dimdate,
    //   payload.content,
    // );
    yield put(SummaryActions.summarySuccess());
  } catch (e) {
    yield put(SummaryActions.summaryFailure(e));
  }
}
