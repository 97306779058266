import { call, put } from 'redux-saga/effects';
import { creators as TemplateActions } from 'Reducers/Template';
import { toast } from 'react-toastify';

import TemplateApi from 'Api/TemplateApi';

export function* getTemplates() {
  try {
    const response = yield call(TemplateApi.getAllTemplates, null);

    if (response.status === 200)
      yield put(TemplateActions.templateSuccess({ templates: response.data }));
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(TemplateActions.templateFailure(e));
  }
}
