import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
  dispatchFilePostJSONAsync,
  dispatchPostFormAsync,
} from './BaseApi';

async function getClient() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function getCompanyData(DimClientKey) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/get`;
  return await dispatchPostJSONAsync(url, DimClientKey);
}

async function addClient(company_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/add`;
  return await dispatchPostJSONAsync(url, company_data);
}

async function updateClient(client_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/update`;
  return await dispatchPostJSONAsync(url, client_data);
}

async function deleteClient(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    client_id,
  });
}

async function getClientHistory(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/history`;
  return await dispatchPostJSONAsync(url, { client_id });
}

async function updateClientHistory(client_id, updated_log) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/update_history`;
  return await dispatchPostJSONAsync(url, { client_id, updated_log });
}

async function getClientPhoto(image_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/get_photo`;
  return await dispatchFilePostJSONAsync(url, { image_id });
}

async function getCompanyGeneral(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/general/get/${client_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToCompanyGeneral(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/general/add`;
  return await dispatchPostJSONAsync(url, { client_id });
}

async function deleteRowFromCompanyGeneral(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/general/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updateCompanyGeneral(client_id, updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/general/update`;
  return await dispatchPostJSONAsync(url, {
    client_id,
    updated_data,
  });
}

async function addPeopleFromCompany(people_detail) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/general/add_people`;
  return await dispatchPostJSONAsync(url, people_detail);
}

async function getContactPoints() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/get`;
  return await dispatchGetJSONAsync(url);
}

async function getCompanyContactPoints(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/get/${client_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyRowToCompanyContactPoints(client_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/add_empty`;
  return await dispatchPostJSONAsync(url, { client_id });
}

async function addCompanyContactPoints(add_contact_point) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/add`;
  return await dispatchPostJSONAsync(url, add_contact_point);
}

async function deleteRowFromCompanyContactPoints(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/delete`;
  return await dispatchPostJSONAsync(url, {
    row_id,
  });
}

async function updateCompanyContactPoints(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points/update`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

async function getCompanyContactPeoplePoints(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/contact_points_people/get/${people_id}`;
  return await dispatchGetJSONAsync(url);
}

async function uploadClientPhoto(client_id, photoFile) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/datacompany/${company.id}/upload_photo`;
  let data = new FormData();
  data.append('client_id', client_id);
  data.append('photo_file', photoFile);
  data.append('file_name', photoFile.name);
  return await dispatchPostFormAsync(url, data);
}

async function uploadCompanyFieldPhoto(photoFile, complete) {
  let reader = new FileReader();
  reader.readAsDataURL(photoFile);
  reader.onload = async () => {
    const company = appdata.getItem('company');
    const user = appdata.getItem('user');
    const url = `${API_ROOT}/datacompany/${company.id}/${user.id}/upload_field_photo`;
    let res = await dispatchPostJSONAsync(url, {
      user_id: user.id,
      photo_file: reader.result.split(',')[1],
    });
    complete(res);
  };
}

const DataComapnyApi = {
  getClient,
  getCompanyData,
  addClient,
  updateClient,
  updateClientHistory,
  deleteClient,
  getClientHistory,
  getClientPhoto,
  getCompanyGeneral,
  addEmptyRowToCompanyGeneral,
  deleteRowFromCompanyGeneral,
  updateCompanyGeneral,
  addPeopleFromCompany,
  getContactPoints,
  addEmptyRowToCompanyContactPoints,
  addCompanyContactPoints,
  deleteRowFromCompanyContactPoints,
  updateCompanyContactPoints,
  getCompanyContactPoints,
  getCompanyContactPeoplePoints,
  uploadClientPhoto,
  uploadCompanyFieldPhoto,
};

export default DataComapnyApi;
