import { createActions, handleActions } from 'redux-actions';

const initState = {
  templates: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  TEMPLATE_GET_TEMPLATES: (payload) => payload,
  TEMPLATE_SUCCESS: (payload) => payload,
  TEMPLATE_FAILURE: (payload) => payload,
});

const getTemplatesReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    TEMPLATE_GET_TEMPLATES: getTemplatesReducer,
    TEMPLATE_SUCCESS: successReducer,
    TEMPLATE_FAILURE: failureReducer,
  },
  initState,
);
