import { createActions, handleActions } from 'redux-actions';

const initState = {
  company_settings: [],
  organisation_settings: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  COMPANY_SETTINGS_GET_ALL_COMPANY_SETTINGS: (payload) => payload,
  ORGANISATION_SETTINGS_GET_ALL_ORGANISATION_SETTINGS: (payload) => payload,
  COMPANY_SETTINGS_SUCCESS: (payload) => payload,
  COMPANY_SETTINGS_FAILURE: (payload) => payload,
});

const getAllCompanySettingsReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getAllOrganisationsReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    COMPANY_SETTINGS_GET_ALL_COMPANY_SETTINGS: getAllCompanySettingsReducer,
    ORGANISATION_SETTINGS_GET_ALL_ORGANISATION_SETTINGS: getAllOrganisationsReducer,
    COMPANY_SETTINGS_SUCCESS: successReducer,
    COMPANY_SETTINGS_FAILURE: failureReducer,
  },
  initState,
);
