import { createActions, handleActions } from 'redux-actions';

const initState = {
  packageSettings: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  PMANAGEMENT_GET_PACKAGE_SETTINGS: (payload) => payload,
  PMANAGEMENT_SUCCESS: (payload) => payload,
  PMANAGEMENT_FAILURE: (payload) => payload,
});

const getPackageSettingsReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    PMANAGEMENT_GET_PACKAGE_SETTINGS: getPackageSettingsReducer,
    PMANAGEMENT_SUCCESS: successReducer,
    PMANAGEMENT_FAILURE: failureReducer,
  },
  initState,
);
