import { createActions, handleActions } from 'redux-actions';
import { database } from 'Assets/js/constant';

const initState = {
  database: [],
  selectedDatabases: [],
  selectedDimensions: [],
  selectedMetrics: [],
  sortBy: {},
  page: 0,
  rowsPerPage: 10,
  filter: [],
  groupBy: [],
  search: '',
  chart: {
    type: 'line',
    direction: 'vertical',
    scale: 'shrink',
  },
  report: '',
  view: 0, // 0: table, 1: chart
  notification: {
    open: false,
    message: '',
    variant: 'success',
  },
};

export const creators = createActions({
  LOAD_DATABASE: (payload) => payload,
  SELECT_DATABASE: (payload) => payload,
  SELECT_DIMENSION: (payload) => payload,
  MOVE_DIMENSION: (payload) => payload,
  SELECT_METRIC: (payload) => payload,
  MOVE_METRIC: (payload) => payload,
  SET_SORT_BY: (payload) => payload,
  SET_PAGE: (payload) => payload,
  SET_ROWS_PER_PAGE: (payload) => payload,
  SET_FILTER: (payload) => payload,
  SET_GROUP_BY: (payload) => payload,
  SET_SEARCH: (payload) => payload,
  SET_CHART: (payload) => payload,
  LOAD_SETTINGS: (payload) => payload,
  SET_REPORT: (payload) => payload,
  SET_VIEW: (payload) => payload,
  OPEN_NOTIFICATION: (payload) => payload,
  CLOSE_NOTIFICATION: (payload) => payload,
});

const loadDatabaseReducer = (state) => {
  return { ...state, database: database };
};

const selectDatabaseReducer = (state, { payload }) => {
  const index = payload.index;
  const item = payload.item;
  const selected = payload.selected;
  const linked = payload.linked;
  const parents = payload.parents;
  if (selected) {
    const selectedDatabases = state.selectedDatabases.filter((dataSource) => {
      let exist = false;
      dataSource.parents.forEach((parent) => {
        if (parent.database === index) {
          exist = true;
          return;
        }
      });
      return !exist;
    });
    const newSelectedDatabases = selectedDatabases.filter(
      (dataSource) => dataSource.index !== index,
    );
    const newSortBy = { ...state.sortBy };
    for (const key in state.sortBy) {
      let exist = false;
      newSelectedDatabases.forEach((selectedDatabase) => {
        if (key === selectedDatabase.index) {
          exist = true;
          return;
        }
      });
      if (!exist) {
        delete newSortBy[key];
      }
    }
    return {
      ...state,
      selectedDatabases: newSelectedDatabases,
      selectedDimensions: state.selectedDimensions.filter((dimension) => {
        let exist = false;
        newSelectedDatabases.forEach((selectedDatabase) => {
          if (dimension.database === selectedDatabase.index) {
            exist = true;
            return;
          }
        });
        return exist;
      }),
      selectedMetrics: state.selectedMetrics.filter((metric) => {
        let exist = false;
        newSelectedDatabases.forEach((selectedDatabase) => {
          if (metric.database === selectedDatabase.index) {
            exist = true;
            return;
          }
        });
        return exist;
      }),
      sortBy: newSortBy,
      page: 0,
      filter: state.filter.filter((item) => {
        let exist = false;
        newSelectedDatabases.forEach((selectedDatabase) => {
          if (item.database === selectedDatabase.index) {
            exist = true;
            return;
          }
        });
        return exist;
      }),
      groupBy: state.groupBy.filter((item) => {
        let exist = false;
        newSelectedDatabases.forEach((selectedDatabase) => {
          if (item.database === selectedDatabase.index) {
            exist = true;
            return;
          }
        });
        return exist;
      }),
      search: '',
    };
  } else {
    const newDimensions = [],
      newMetrics = [];
    const selectedDatabase = state.database[index];
    if (selectedDatabase.dimensions && selectedDatabase.dimensions.length > 0) {
      const dimensions = state.database[index].dimensions;
      dimensions.forEach((dimension) => {
        newDimensions.push({
          ...dimension,
          selected: false,
          database: index,
        });
      });
    }
    if (selectedDatabase.metrics && selectedDatabase.metrics.length > 0) {
      const metrics = state.database[index].metrics;
      metrics.forEach((metric) => {
        newMetrics.push({ ...metric, selected: false, database: index });
      });
    }
    let newSelectedDatabases = [];
    let newSelectedDimensions = [];
    let newSelectedMetrics = [];
    if (linked) {
      newSelectedDatabases = state.selectedDatabases.concat([
        {
          index: index,
          name: item.database,
          table: item.tablename,
          api: item.api,
          parents: parents,
        },
      ]);
      newSelectedDimensions = state.selectedDimensions.concat(newDimensions);
      newSelectedMetrics = state.selectedMetrics.concat(newMetrics);
      return {
        ...state,
        selectedDatabases: newSelectedDatabases,
        selectedDimensions: newSelectedDimensions,
        selectedMetrics: newSelectedMetrics,
        page: 0,
      };
    } else {
      newSelectedDatabases = [
        {
          index: index,
          name: item.database,
          table: item.tablename,
          api: item.api,
          parents: [],
        },
      ];
      newSelectedDimensions = newDimensions;
      newSelectedMetrics = newMetrics;
      return {
        ...state,
        selectedDatabases: newSelectedDatabases,
        selectedDimensions: newSelectedDimensions,
        selectedMetrics: newSelectedMetrics,
        sortBy: {},
        page: 0,
        filter: [],
        groupBy: [],
        search: '',
      };
    }
  }
};

const selectDimensionReducer = (state, { payload }) => {
  const selectedDimension = state.selectedDimensions[payload.index];
  if (selectedDimension.selected) {
    const newSortBy = { ...state.sortBy };
    Object.keys(state.sortBy).forEach((key) => {
      if (
        key === selectedDimension.database &&
        state.sortBy[key].column === selectedDimension.key
      ) {
        delete newSortBy[key];
      }
    });
    return {
      ...state,
      selectedDimensions: state.selectedDimensions.map((element, index) =>
        index === payload.index
          ? { ...element, selected: !element.selected }
          : element,
      ),
      sortBy: newSortBy,
      filter: state.filter.filter(
        (item) =>
          item.database !== state.selectedDimensions[payload.index].database ||
          item.column !== state.selectedDimensions[payload.index].key,
      ),
      groupBy: state.groupBy.filter(
        (item) =>
          item.database !== state.selectedDimensions[payload.index].database ||
          item.column !== state.selectedDimensions[payload.index].key,
      ),
    };
  } else {
    return {
      ...state,
      selectedDimensions: state.selectedDimensions.map((element, index) =>
        index === payload.index
          ? { ...element, selected: !element.selected }
          : element,
      ),
    };
  }
};

const moveDimensionReducer = (state, { payload }) => {
  const dimension = state.selectedDimensions[payload.curIdx];
  const newState = {
    ...state,
    selectedDimensions: state.selectedDimensions.filter(
      (element, index) => index !== payload.curIdx,
    ),
  };
  const newDimensions = newState.selectedDimensions;
  newDimensions.splice(payload.destIdx, 0, dimension);
  return {
    ...newState,
    selectedDimensions: newDimensions,
  };
};

const selectMetricReducer = (state, { payload }) => {
  const selectedMetric = state.selectedMetrics[payload.index];
  if (state.selectedMetrics[payload.index].selected) {
    const newSortBy = { ...state.sortBy };
    Object.keys(state.sortBy).forEach((key) => {
      if (
        key === selectedMetric.database &&
        state.sortBy[key].column === selectedMetric.key
      ) {
        delete newSortBy[key];
      }
    });
    return {
      ...state,
      selectedMetrics: state.selectedMetrics.map((element, index) =>
        index === payload.index
          ? { ...element, selected: !element.selected }
          : element,
      ),
      sortBy: newSortBy,
      filter: state.filter.filter(
        (item) =>
          item.database !== state.selectedMetrics[payload.index].database ||
          item.column !== state.selectedMetrics[payload.index].key,
      ),
    };
  } else {
    return {
      ...state,
      selectedMetrics: state.selectedMetrics.map((element, index) =>
        index === payload.index
          ? { ...element, selected: !element.selected }
          : element,
      ),
    };
  }
};

const moveMetricReducer = (state, { payload }) => {
  const metric = state.selectedMetrics[payload.curIdx];
  const newState = {
    ...state,
    selectedMetrics: state.selectedMetrics.filter(
      (element, index) => index !== payload.curIdx,
    ),
  };
  const newMetrics = newState.selectedMetrics;
  newMetrics.splice(payload.destIdx, 0, metric);
  return {
    ...newState,
    selectedMetrics: newMetrics,
  };
};

const setSortByReducer = (state, { payload }) => {
  const database = payload.database;
  const column = payload.column;
  const newSortBy = { ...state.sortBy };
  if (newSortBy[database]) {
    if (newSortBy[database].column === payload.column) {
      if (payload.direction) {
        newSortBy[database].direction = payload.direction;
      } else {
        if (newSortBy[database].direction === 'asc') {
          newSortBy[database].direction = 'desc';
        } else {
          newSortBy[database].direction = 'asc';
        }
      }
    } else {
      newSortBy[database].column = column;
      if (payload.direction) {
        newSortBy[database].direction = payload.direction;
      } else {
        newSortBy[database].direction = 'desc';
      }
    }
  } else {
    newSortBy[database] = {};
    newSortBy[database].column = column;
    if (payload.direction) {
      newSortBy[database].direction = payload.direction;
    } else {
      newSortBy[database].direction = 'desc';
    }
  }

  return {
    ...state,
    sortBy: newSortBy,
  };
};

const setPageReducer = (state, { payload }) => {
  return {
    ...state,
    page: payload.page,
  };
};

const setRowsPerPageReducer = (state, { payload }) => {
  return {
    ...state,
    page: 0,
    rowsPerPage: payload.rowsPerPage,
  };
};

const setFilterReducer = (state, { payload }) => {
  return {
    ...state,
    filter: payload.filter,
    page: 0,
  };
};

const setGroupByReducer = (state, { payload }) => {
  return {
    ...state,
    groupBy: payload.groupBy,
    page: 0,
  };
};

const setSearchReducer = (state, { payload }) => {
  return {
    ...state,
    search: payload.search,
    page: 0,
  };
};

const setChartReducer = (state, { payload }) => {
  return {
    ...state,
    chart: payload.chart,
  };
};

const loadSettingsReducer = (state, { payload }) => {
  return {
    ...state,
    selectedDatabases: payload.settings.selectedDatabases,
    selectedDimensions: payload.settings.selectedDimensions,
    selectedMetrics: payload.settings.selectedMetrics,
    sortBy: payload.settings.sortBy,
    page: payload.settings.page,
    rowsPerPage: payload.settings.rowsPerPage,
    filter: payload.settings.filter,
    groupBy: payload.settings.groupBy,
    search: payload.settings.search,
    chart: payload.settings.chart,
    view: payload.settings.view,
  };
};

const setReportReducer = (state, { payload }) => {
  return {
    ...state,
    report: payload.report,
  };
};

const setViewReducer = (state, { payload }) => {
  return {
    ...state,
    view: payload.view,
  };
};

const openNotificationReducer = (state, { payload }) => {
  return {
    ...state,
    notification: {
      open: true,
      message: payload.notification.message,
      variant: payload.notification.variant,
    },
  };
};

const closeNotificationReducer = (state) => {
  return {
    ...state,
    notification: {
      ...state.notification,
      open: false,
    },
  };
};

export default handleActions(
  {
    LOAD_DATABASE: loadDatabaseReducer,
    SELECT_DATABASE: selectDatabaseReducer,
    SELECT_DIMENSION: selectDimensionReducer,
    MOVE_DIMENSION: moveDimensionReducer,
    SELECT_METRIC: selectMetricReducer,
    MOVE_METRIC: moveMetricReducer,
    SET_SORT_BY: setSortByReducer,
    SET_PAGE: setPageReducer,
    SET_ROWS_PER_PAGE: setRowsPerPageReducer,
    SET_FILTER: setFilterReducer,
    SET_GROUP_BY: setGroupByReducer,
    SET_SEARCH: setSearchReducer,
    SET_CHART: setChartReducer,
    LOAD_SETTINGS: loadSettingsReducer,
    SET_REPORT: setReportReducer,
    SET_VIEW: setViewReducer,
    OPEN_NOTIFICATION: openNotificationReducer,
    CLOSE_NOTIFICATION: closeNotificationReducer,
  },
  initState,
);
