import { call, put } from 'redux-saga/effects';
import { creators as MTDBActions } from 'Reducers/MTDB';
import { toast } from 'react-toastify';

import AdminApi from 'Api/AdminApi';

export function* getTables() {
  try {
    const response = yield call(AdminApi.getTables, null);
    if (response.status === 200) {
      yield put(MTDBActions.mtdbSuccess({ tables: response.data }));
    } else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(MTDBActions.mtdbFailure(e));
  }
}
