import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
  dispatchFilePostJSONAsync,
  dispatchPostFormAsync,
} from './BaseApi';

async function getProjects() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProjectAllTasks() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/task/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProject() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function getSelectedProject(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get`;
  return await dispatchPostJSONAsync(url, { project_id, project_type });
}

async function getProjectPhoto(project_type, image_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_photo`;
  return await dispatchFilePostJSONAsync(url, { project_type, image_id });
}

async function addProject(project_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/add`;
  return await dispatchPostJSONAsync(url, project_data);
}

async function copyProject(copy_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/copy`;
  return await dispatchPostJSONAsync(url, copy_data);
}

async function deleteProject(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
  });
}

async function updateProject(project_id, project_type, project_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    ...project_data,
  });
}

async function getProjectHistory(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/history`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
  });
}

async function uploadProjectPhoto(project_id, project_type, photoFile) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/upload_photo`;
  let data = new FormData();

  data.append('project_id', project_id);
  data.append('project_type', project_type);
  data.append('photo_file', photoFile);
  data.append('file_name', photoFile.name);
  return await dispatchPostFormAsync(url, data);
}

// async function getProjectClients(project_id, project_type) {
//   const company = appdata.getItem('company');
//   const url = `${API_ROOT}/dataproject/${company.id}/clients`;
//   return await dispatchPostJSONAsync(url, { project_id, project_type });
// }

// async function addProjectClients(
//   project_id,
//   project_type,
//   client_id,
//   send_bill,
// ) {
//   const company = appdata.getItem('company');
//   const url = `${API_ROOT}/dataproject/${company.id}/clients/add`;
//   return await dispatchPostJSONAsync(url, {
//     project_id,
//     project_type,
//     client_id,
//     send_bill,
//   });
// }

async function getAllProjectTeam() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProjectTeam(project_id, project_type, client_key) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/get`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    client_key,
  });
}

async function getMyProjects(DimPeopleKey) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/get_my_projects`;
  return await dispatchPostJSONAsync(url, {
    DimPeopleKey,
  });
}

async function getPeopleMilestone(DimPeopleKey) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/get_people_milestone`;
  return await dispatchPostJSONAsync(url, {
    DimPeopleKey,
  });
}

async function filterProjectTeam(role) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/filter`;
  return await dispatchPostJSONAsync(url, { role });
}

async function addProjectTeam(project_id, project_type, unactive_id, add_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/add`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    unactive_id,
    ...add_data,
  });
}

async function deleteProjectTeam(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/delete`;
  return await dispatchPostJSONAsync(url, { row_id });
}

async function updateProjectTeam(
  project_id,
  project_type,
  unactive_id,
  updated_data,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/update`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    unactive_id,
    ...updated_data,
  });
}

async function updatePriorityProjectTeam(priority_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/update_priority`;
  return await dispatchPostJSONAsync(url, {
    priority_data,
  });
}

async function getJSONProjectNumberName(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/team/get_json`;
  return await dispatchPostJSONAsync(url, {
    people_id,
  });
}

async function getProjectTasks(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/tasks`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
  });
}

async function getAllProjectTasks() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_all_tasks`;
  return await dispatchGetJSONAsync(url);
}

async function getPeopleTasks(people_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_people_tasks`;
  return await dispatchPostJSONAsync(url, {
    people_id,
  });
}

async function addProjectTasks(project_type, add_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/tasks/add`;
  return await dispatchPostJSONAsync(url, {
    project_type,
    ...add_data,
  });
}

async function deleteProjectTasks(row_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/tasks/delete`;
  return await dispatchPostJSONAsync(url, { row_id });
}

async function updateProjectTasks(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/tasks/update`;
  return await dispatchPostJSONAsync(url, { updated_data });
}

async function getForecastInitial() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/forecast`;
  return await dispatchGetJSONAsync(url);
}

async function getAllProjectRelatedData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/related/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProjectResources(type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/get_all`;
  return await dispatchPostJSONAsync(url, { type });
}

async function getProjectResource(project_id, project_type, task_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/get`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
  });
}

async function getPeopleTotalHourResource(people_id, start_date, end_date) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/total_hour`;
  return await dispatchPostJSONAsync(url, {
    people_id,
    start_date,
    end_date,
  });
}

async function addProjectResource(project_type, input_type, resource_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/add`;
  return await dispatchPostJSONAsync(url, {
    project_type,
    input_type,
    resource_data,
  });
}

async function deleteProjectResource(
  project_id,
  project_type,
  task_id,
  mode,
  key,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/delete`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
    mode,
    key,
  });
}

async function deleteProjectResourceForecast(resourceIds) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/delete_forecast`;
  return await dispatchPostJSONAsync(url, {
    resourceIds,
  });
}

async function updateProjectResourceFromSimple(data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/update_simple`;
  return await dispatchPostJSONAsync(url, data);
}

// async function updateProjectResource(resource_data) {
//   const company = appdata.getItem('company');
//   const url = `${API_ROOT}/dataproject/${company.id}/resource/update`;
//   return await dispatchPostJSONAsync(url, {
//     resource_data,
//   });
// }

async function confirmProjectResource(
  mode,
  hrs,
  days,
  people_data,
  rate_data,
  company_data,
  addNewDays,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/confirm`;
  return await dispatchPostJSONAsync(url, {
    mode,
    hrs,
    days,
    people_data,
    rate_data,
    company_data,
    addNewDays,
  });
}

async function addResourceHoursByDate(add_resource_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/add_hours`;
  return await dispatchPostJSONAsync(url, {
    add_resource_data,
  });
}

async function updateResourceHoursByIds(update_resource_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/update_hours`;
  return await dispatchPostJSONAsync(url, {
    update_resource_data,
  });
}

async function updateResourceAmountByIds(update_resource_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/update_amount`;
  return await dispatchPostJSONAsync(url, {
    update_resource_data,
  });
}

async function swapResourceData(swap_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/swap`;
  return await dispatchPostJSONAsync(url, swap_data);
}

async function addClientResource(
  project_type,
  add_company_resources,
  company_key,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/add_client_resources`;
  return await dispatchPostJSONAsync(url, {
    project_type,
    add_company_resources,
    company_key,
  });
}

async function deleteClientResource(
  project_id,
  project_type,
  task_id,
  company_key,
  client_key,
  delete_company_resources,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/delete_client_resources`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
    company_key,
    client_key,
    delete_company_resources,
  });
}

async function updateClientResource(update_company_resources) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/resource/update_client_resources`;
  return await dispatchPostJSONAsync(url, {
    update_company_resources,
  });
}

// async function getProjectType() {
//   const company = appdata.getItem('company');
//   const url = `${API_ROOT}/dataproject/${company.id}/get_project_type`;
//   return await dispatchGetJSONAsync(url);
// }

// async function getProjectSubType() {
//   const company = appdata.getItem('company');
//   const url = `${API_ROOT}/dataproject/${company.id}/get_project_sub_type`;
//   return await dispatchGetJSONAsync(url);
// }

// async function uploadProjectFieldPhoto(photoFile, complete) {
//   let reader = new FileReader();
//   reader.readAsDataURL(photoFile);
//   reader.onload = async () => {
//     const company = appdata.getItem('company');
//     const user = appdata.getItem('user');
//     const url = `${API_ROOT}/dataproject/${company.id}/${user.id}/upload_field_photo`;
//     let res = await dispatchPostJSONAsync(url, {
//       user_id: user.id,
//       photo_file: reader.result.split(',')[1],
//     });
//     complete(res.photo_url);
//   };
// }

async function getAllProjectInvoice() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProjectTaskInvoice(project_id, project_type, task_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/get`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
  });
}

async function addProjectInvoice(project_type, invoice_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/add`;
  return await dispatchPostJSONAsync(url, { project_type, invoice_data });
}

async function deleteProjectInvoice(project_id, project_type, task_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/delete`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
  });
}

async function deleteProjectInvoiceWithIds(project_type, invoiceIds) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/delete_ids`;
  return await dispatchPostJSONAsync(url, {
    project_type,
    invoiceIds,
  });
}

async function updateProjectInvoice(project_type, invoice_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/invoice/update`;
  return await dispatchPostJSONAsync(url, { project_type, invoice_data });
}

async function getAllProjectMilestones(filter_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/get_all`;
  return await dispatchPostJSONAsync(url, filter_data);
}

async function getProjectMilestone(project_id, project_type, task_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/get`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
  });
}

async function addProjectMilestone(project_type, milestone_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/add`;
  return await dispatchPostJSONAsync(url, {
    project_type,
    ...milestone_data,
  });
}

async function deleteProjectMilestone(
  project_id,
  project_type,
  task_id,
  milestone_id,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/delete`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    task_id,
    milestone_id,
  });
}

async function deleteAllProjectMilestone(milestone_ids) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/delete_all`;
  return await dispatchPostJSONAsync(url, {
    milestone_ids,
  });
}

async function updateProjectMilestone(milestone_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/update`;
  return await dispatchPostJSONAsync(url, { ...milestone_data });
}

async function updateAllProjectMilestone(milestone_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/milestone/update_all`;
  return await dispatchPostJSONAsync(url, { milestone_data });
}

async function getProjectFiles(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_file`;
  return await dispatchPostJSONAsync(url, { project_id, project_type });
}

async function downloadProjectFile(file_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.company_id}/project_file/download`;
  return await dispatchPostJSONAsync(url, { file_id });
}

async function addProjectFile(project_id, project_type, date, type, file) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_file/add`;
  let data = new FormData();
  data.append('project_id', project_id);
  data.append('project_type', project_type);
  data.append('Date', date);
  data.append('Type', type);
  data.append('file', file);
  data.append('file_name', file.name);
  return await dispatchPostFormAsync(url, data);
}

async function updateProjectFile(id, date, type, file) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_file/update`;
  let data = new FormData();
  data.append('id', id);
  data.append('Date', date);
  data.append('Type', type);
  data.append('file', file);
  data.append('file_name', file.name);
  return await dispatchPostFormAsync(url, data);
}

async function updateProjectData(project_file_id, date, type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_file/update_data`;
  return await dispatchPostJSONAsync(url, {
    project_file_id,
    Date: date,
    Type: type,
  });
}

async function deleteProjectFile(project_file_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_file/delete_file`;
  return await dispatchPostJSONAsync(url, {
    project_file_id,
  });
}

async function getProjectPdfs(project_id, project_type) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_pdf`;
  return await dispatchPostJSONAsync(url, { project_id, project_type });
}

async function addProjectPdf(project_id, project_type, date, file) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_pdf/add`;
  let data = new FormData();
  data.append('project_id', project_id);
  data.append('project_type', project_type);
  data.append('Date', date);
  data.append('file', file);
  data.append('file_name', file.name);
  return await dispatchPostFormAsync(url, data);
}

async function updateProjectPdfFile(id, date, file) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_pdf/update`;
  let data = new FormData();
  data.append('id', id);
  data.append('Date', date);
  data.append('file', file);
  data.append('file_name', file.name);
  return await dispatchPostFormAsync(url, data);
}

async function updateProjectPdfData(update_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_pdf/update_data`;
  return await dispatchPostJSONAsync(url, update_data);
}

async function deleteProjectPdf(pdf_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/project_pdf/delete`;
  return await dispatchPostJSONAsync(url, {
    pdf_id,
  });
}

//dashboard security

async function getDimOpportunities() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_opportunity`;
  return await dispatchGetJSONAsync(url);
}

async function getDimProject() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_project`;
  return await dispatchGetJSONAsync(url);
}

async function getDimLead() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_lead`;
  return await dispatchGetJSONAsync(url);
}

async function getDimProspect() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_prospect`;
  return await dispatchGetJSONAsync(url);
}

async function getResourceRelateTasks(project_info) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/get_relate_task`;
  return await dispatchPostJSONAsync(url, project_info);
}

async function moveResourceRelateTasks(resource_info) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/move_relate_task`;
  return await dispatchPostJSONAsync(url, resource_info);
}

async function moveResourceTasks(resources, invoices, milestones) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/move_resources`;
  return await dispatchPostJSONAsync(url, {
    resources,
    invoices,
    milestones,
  });
}

async function resetSimpleResources(project_info) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/reset_simple_resource`;
  return await dispatchPostJSONAsync(url, project_info);
}

async function getAllProjectRates() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/rate/get_all`;
  return await dispatchGetJSONAsync(url);
}

async function getProjectRates(project_info) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/rate/get`;
  return await dispatchPostJSONAsync(url, project_info);
}

async function addProjectRate(rate_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/rate/add`;
  return await dispatchPostJSONAsync(url, rate_data);
}

async function editProjectRate(rate_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/rate/edit`;
  return await dispatchPostJSONAsync(url, rate_data);
}

async function deleteProjectRate(rate_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/rate/delete`;
  return await dispatchPostJSONAsync(url, rate_id);
}

async function deleteOutSideRelatedData(
  project_id,
  project_type,
  start_date,
  end_date,
) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/dataproject/${company.id}/related_data/delete`;
  return await dispatchPostJSONAsync(url, {
    project_id,
    project_type,
    start_date,
    end_date,
  });
}

const DataProjectApi = {
  getProjects,
  getProjectAllTasks,
  getProject,
  getSelectedProject,
  getProjectPhoto,
  addProject,
  copyProject,
  deleteProject,
  updateProject,
  getProjectHistory,
  uploadProjectPhoto,
  getPeopleTasks,
  getPeopleMilestone,
  getAllProjectTasks,

  //   uploadProjectFieldPhoto,

  //   getProjectClients,
  //   addProjectClients,
  getAllProjectTeam,
  getProjectTeam,
  filterProjectTeam,
  addProjectTeam,
  deleteProjectTeam,
  updateProjectTeam,
  updatePriorityProjectTeam,
  getJSONProjectNumberName,
  getProjectTasks,
  addProjectTasks,
  deleteProjectTasks,
  updateProjectTasks,
  getForecastInitial,
  getAllProjectRelatedData,
  getProjectResources,
  getProjectResource,
  getPeopleTotalHourResource,
  addProjectResource,
  deleteProjectResource,
  deleteProjectResourceForecast,
  updateProjectResourceFromSimple,
  //   updateProjectResource,
  confirmProjectResource,
  addResourceHoursByDate,
  updateResourceHoursByIds,
  updateResourceAmountByIds,
  swapResourceData,
  addClientResource,
  deleteClientResource,
  updateClientResource,
  //   getProjectType,
  //   getProjectSubType,
  getAllProjectInvoice,
  getProjectTaskInvoice,
  addProjectInvoice,
  deleteProjectInvoice,
  deleteProjectInvoiceWithIds,
  updateProjectInvoice,
  getAllProjectMilestones,
  getProjectMilestone,
  addProjectMilestone,
  deleteProjectMilestone,
  deleteAllProjectMilestone,
  updateProjectMilestone,
  updateAllProjectMilestone,
  getProjectFiles,
  downloadProjectFile,
  addProjectFile,
  updateProjectFile,
  updateProjectData,
  deleteProjectFile,
  getProjectPdfs,
  addProjectPdf,
  deleteProjectPdf,
  updateProjectPdfFile,
  updateProjectPdfData,

  //dashboard security
  getDimOpportunities,
  getDimProject,
  getDimLead,
  getDimProspect,

  getResourceRelateTasks,
  moveResourceRelateTasks,
  moveResourceTasks,
  resetSimpleResources,

  getAllProjectRates,
  getProjectRates,
  addProjectRate,
  editProjectRate,
  deleteProjectRate,
  deleteOutSideRelatedData,
  getMyProjects
};

export default DataProjectApi;
