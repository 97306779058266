import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const ApiDebtors = () => {
  const getSummary = async ({ selectedYears, defaultYear, defaultMonth }) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    const filter = { years: _selectedYears };

    queryParams +=
      '?filter=' +
      JSON.stringify(filter) +
      '&dyear=' +
      defaultYear +
      '&dmonth=' +
      defaultMonth;

    return await dispatchGetJSONAsync(
      `${API_ROOT}/debtors/${company_id}/summary${queryParams}`,
    );
  };

  const getDetail = async ({ selectedYears, defaultYear, defaultMonth }) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;
    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    let filter = { years: _selectedYears };

    queryParams +=
      '?filter=' +
      JSON.stringify(filter) +
      '&dyear=' +
      defaultYear +
      '&dmonth=' +
      defaultMonth;

    return await dispatchGetJSONAsync(
      `${API_ROOT}/debtors/${company_id}/detail${queryParams}`,
    );
  };

  return {
    getSummary,
    getDetail,
  };
};

export default ApiDebtors();
