import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'Reducers';
import rootSaga from 'Sagas';

export function configureStore(initialState = {}) {
  // Middleware and Store enhancers
  const sagaMiddleware = createSagaMiddleware();
  const enhancers = [applyMiddleware(sagaMiddleware)];

  if (process.env.NODE_ENV !== 'production') {
    enhancers.push(applyMiddleware());
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(rootReducer, initialState, compose(...enhancers));
  // const store = createStore(rootReducer, initialState, composeWithDevTools(...enhancers));
  sagaMiddleware.run(rootSaga);

  return store;
}
