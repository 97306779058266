import { call, put } from 'redux-saga/effects';
import { creators as AdminActions } from 'Reducers/Admin';
import { toast } from 'react-toastify';

import AdminApi from 'Api/AdminApi';

export function* getAllCompanies() {
  try {
    const response = yield call(AdminApi.getAllCompanies, null);

    if (response.status === 200)
      yield put(AdminActions.adminSuccess({ companies: response.data }));
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(AdminActions.adminFailure(e));
  }
}
