import appdata from '../Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
  dispatchPostFormAsync,
  dispatchFilePostJSONAsync,
} from './BaseApi';

async function addNewExpense(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/add_new_expense`;
  return await dispatchPostJSONAsync(url, payload);
}

async function getExpensePhoto(image_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/get_photo`;
  return await dispatchPostJSONAsync(url, { image_id });
}

async function uploadExpensePhoto(expense_id, photoFile) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/upload_photo`;
  let data = new FormData();
  data.append('expense_id', expense_id);
  data.append('photo_file', photoFile);
  data.append('file_name', photoFile.name);
  return await dispatchPostFormAsync(url, data);
}

async function getExpenseData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/get_expense_data`;
  return await dispatchGetJSONAsync(url);
}

async function CheckExpenseData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/check_expense_data`;
  return await dispatchGetJSONAsync(url);
}

async function delExpense(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/del_expense`;
  return await dispatchPostJSONAsync(url, payload);
}

async function editNewExpense(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/expense/${company.id}/edit_expense`;
  return await dispatchPostJSONAsync(url, payload);
}

const ExpenseApi = {
  addNewExpense,
  getExpensePhoto,
  uploadExpensePhoto,
  getExpenseData,
  delExpense,
  editNewExpense,
  CheckExpenseData
};

export default ExpenseApi;
