import React, { createContext, useEffect, useState, useMemo } from 'react';
import AppRoutes from 'Routes';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import appdata from 'Services/appdata';
import stream from 'Services/stream';
import ProvideSsoContext from './ssoContext';

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

const darkStyle = `
  .e-grid:not(.e-row-responsive) .e-gridcontent tr.e-row:first-child .e-rowcell {
    background-color: #303030;
  }

  /* BusinessDevelopment page */
  .e-kanban .e-kanban-table {
    background-color: #303030;
  }

  .e-kanban .e-kanban-content {
    background-color: #303030;
  }

  .e-kanban-header .e-kanban-content {
    background-color: #303030 !important;
  }

  .e-kanban
    .e-kanban-table.e-content-table
    .e-content-row:not(.e-swimlane-row)
    td {
    background-color: #424242 !important;
  }

  .e-kanban .e-kanban-table .e-header-cells {
    background-color: dimgrey !important;
  }

  .e-kanban .e-kanban-header .e-header-cells .e-header-wrap .e-header-title {
    color: white;
  }

  .e-kanban .e-column-expand::before {
    color: white;
  }

  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-empty-card,
  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-container
    .e-empty-card {
    color: white;
  }

  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-wrapper
    .e-card
    .e-card-content,
  .e-kanban
    .e-kanban-content
    .e-content-row
    .e-content-cells
    .e-card-container
    .e-card
    .e-card-content {
    color: white;
  }

  /* Invoicing Page */
  .rdw-editor-toolbar {
    background: #303030;
  }

  .rdw-option-wrapper {
    border: 1px solid #bfbdbd;
    background: #424242;
  }

  .rdw-dropdown-wrapper,
  .rdw-dropdown-wrapper:hover,
  .rdw-dropdown-optionwrapper,
  .rdw-dropdown-optionwrapper:hover {
    background: #424242;
  }

  .rdw-dropdownoption-active,
  .rdw-dropdownoption-default:hover {
    background: #696969;
  }

  /* Time page */
  .gaNsxB > span,
  .gaNsxB > img,
  .hfHsRs {
    color: white !important;
  }

  .gaNsxB > img:hover {
    color: #696969 !important;
  }

  .boOeFk,
  .boOeFk > div,
  .mnrRs,
  .mnrRs > div {
    background-color: #424242 !important;
  }

  .react-grid-Grid {
    background-color: #303030 !important;
  }

  .react-grid-Cell {
    background-color: #303030 !important;
    color: #fff !important;
  }

  .react-grid-Canvas {
    background-color: #303030 !important;
    color: #fff !important;
  }

  .react-grid-Toolbar {
    background-color: #303030 !important;
    color: #fff !important;
  }

  .react-grid-HeaderCell {
    background-color: #303030 !important;
    color: #fff !important;
  }

  .react-grid-Header {
    background-color: #303030 !important;
  }
`;

const configuration = {
  auth: {
    clientId: stream.ssoConfig.appId,
    redirectUri: stream.ssoConfig.redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    secureCookies: false,
    storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(configuration);

export default function ToggleColorMode() {
  const [mode, setMode] = useState('light');

  useEffect(() => {
    const user = appdata.getItem('user');
    if (user) {
      if (user.dark_mode) setMode('dark');
      else setMode('light');
    }
  }, []);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        const user = appdata.getItem('user');
        const theme_style = document.getElementById('syncfusion-theme-mode');
        if (user) {
          if (user.dark_mode) {
            setMode('dark');
            theme_style.href =
              'https://cdn.syncfusion.com/ej2/material-dark.css';
          } else {
            setMode('light');
            theme_style.href = 'https://cdn.syncfusion.com/ej2/material.css';
          }
        } else {
          setMode('light');
          theme_style.href = 'https://cdn.syncfusion.com/ej2/material.css';
        }
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        typography: { useNextVariants: true },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        {mode === 'dark' && <style>{darkStyle}</style>}
        <MsalProvider instance={pca}>
          <ProvideSsoContext>
            <AppRoutes />
          </ProvideSsoContext>
        </MsalProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
