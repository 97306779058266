import React, { lazy, Suspense } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import Layout from 'Layout';

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
    );
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

const Home = lazy(() => lazyRetry(() => import('Modules/Home')));
const Login = lazy(() => lazyRetry(() => import('Modules/Login')));
const SignUp = lazy(() => lazyRetry(() => import('Modules/Signup')));
const ForgotPassword = lazy(() =>
  lazyRetry(() => import('Modules/ForgotPassword')),
);
const ResetPassword = lazy(() =>
  lazyRetry(() => import('Modules/ResetPassword')),
);
const Invite = lazy(() => lazyRetry(() => import('Modules/Invite')));

const AppRoutes = () => (
  <Suspense
    fallback={
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100vh',
          marginTop: '49vh',
        }}
      >
        <LinearProgress color="secondary" />
      </div>
    }
  >
    <Layout>
      <Routes>
        <Route exact path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home/*" element={<Home />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/token/:token" element={<ResetPassword />} />
        <Route path="/invite/token/:token" element={<Invite />} />
      </Routes>
    </Layout>
  </Suspense>
);

export default AppRoutes;
