import { call, put } from 'redux-saga/effects';

import { creators as FeesActions } from 'Reducers/Reporting/Fees';
import ApiFees from 'Api/ApiFees';

export function* getFeesSummary({ payload }) {
  try {
    const response = yield call(ApiFees.getSummary, payload.selectedYears);
    yield put(FeesActions.feesSuccess({ summaryData: response }));
  } catch (e) {
    yield put(FeesActions.feesFailure(e));
  }
}

export function* getFeesDetail({ payload }) {
  try {
    const response = yield call(ApiFees.getDetail, payload.selectedYears);
    yield put(FeesActions.feesSuccess({ detailData: response }));
  } catch (e) {
    yield put(FeesActions.feesFailure(e));
  }
}
