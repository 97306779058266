import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const ApiProjectPerformance = () => {
  const getSummary = async (selectedYear) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    var queryjson = [];
    queryjson[0] = selectedYear;
    let filter = { FYs: queryjson };

    queryParams += '?filter=' + JSON.stringify(filter);
    return await dispatchGetJSONAsync(
      `${API_ROOT}/projectperformance/${company_id}/summary${queryParams}`,
    );
  };

  const getDetail = async (selectedYear) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    var queryjson = [];
    queryjson[0] = selectedYear;
    let filter = { FYs: queryjson };

    queryParams += '?filter=' + JSON.stringify(filter);
    return await dispatchGetJSONAsync(
      `${API_ROOT}/projectperformance/${company_id}/detail${queryParams}`,
    );
  };

  return {
    getSummary,
    getDetail,
  };
};

export default ApiProjectPerformance();
