import { call, put } from 'redux-saga/effects';

import { creators as ProjectedFinancialPerformanceActions } from 'Reducers/Reporting/ProjectedFinancialPerformance';
import ApiProjectedFinancialPerformance from 'Api/ApiProjectedFinancialPerformance';

export function* getProjectedFinancialPerformanceSummary({ payload }) {
  try {
    const response = yield call(
      ApiProjectedFinancialPerformance.getSummary,
      payload.selectedYears,
    );
    yield put(
      ProjectedFinancialPerformanceActions.prjfinancialperfSuccess({
        summaryData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectedFinancialPerformanceActions.prjfinancialperfFailure(e));
  }
}

export function* getProjectedFinancialPerformanceDetail({ payload }) {
  try {
    const response = yield call(
      ApiProjectedFinancialPerformance.getDetail,
      payload.dimDateKeys,
    );
    yield put(
      ProjectedFinancialPerformanceActions.prjfinancialperfSuccess({
        detailData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectedFinancialPerformanceActions.prjfinancialperfFailure(e));
  }
}
