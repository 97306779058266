import { call, put } from 'redux-saga/effects';
import { creators as PhotosActions } from 'Reducers/Photos';
import DataComapnyApi from 'Api/DataComapnyApi';
import DataPeopleApi from 'Api/DataPeopleApi';
import DataProjectApi from 'Api/DataProjectApi';
// import AccountPayableApi from 'Api/AccountPayableApi';
import ExpenseApi from 'Api/ExpenseApi';
import LeaveApplicationApi from 'Api/LeaveApplicationApi';

export function* getPeoplePhotos({ payload }) {
  try {
    const response = yield call(DataPeopleApi.getPeoplePhoto, payload.Image);
    yield put(
      PhotosActions.getPeoplePhotoSuccess({
        id: payload.id,
        photo: URL.createObjectURL(response),
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

// export function* getInvoicePDF({ payload }) {
//   try {
//     const response = yield call(AccountPayableApi.getInvoicePDF, payload.Image);
//     yield put(
//       PhotosActions.getPeoplePhotoSuccess({
//         id: payload.id,
//         photo: URL.createObjectURL(response),
//       }),
//     );
//   } catch (e) {
//     yield put(PhotosActions.photoFailure(e));
//   }
// }

export function* addPeoplePhoto({ payload }) {
  try {
    let response = yield call(
      DataPeopleApi.uploadPeoplePhoto,
      payload.id,
      payload.file,
    );
    if (response.status === 200) {
      yield put(
        PhotosActions.addPeoplePhotoSuccess({
          id: payload.id,
          photo: payload.photo,
        }),
      );
    } else yield put(PhotosActions.photoFailure(response.message));
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* uploadPeoplePhoto({ payload }) {
  try {
    let response = yield call(
      DataPeopleApi.uploadPeoplePhoto,
      payload.id,
      payload.file,
    );
    if (response.status === 200) {
      yield put(
        PhotosActions.uploadPeoplePhotoSuccess({
          id: payload.id,
          photo: payload.photo,
        }),
      );
    } else yield put(PhotosActions.photoFailure(response.message));
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* getClientPhotos({ payload }) {
  try {
    const response = yield call(DataComapnyApi.getClientPhoto, payload.Image);
    yield put(
      PhotosActions.getClientPhotoSuccess({
        id: payload.id,
        photo: URL.createObjectURL(response),
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* addClientPhoto({ payload }) {
  try {
    yield call(DataComapnyApi.uploadClientPhoto, payload.id, payload.file);
    yield put(
      PhotosActions.addClientPhotoSuccess({
        id: payload.id,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* uploadClientPhoto({ payload }) {
  try {
    yield call(DataComapnyApi.uploadClientPhoto, payload.id, payload.file);
    yield put(
      PhotosActions.uploadClientPhotoSuccess({
        id: payload.id,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* getProjectPhotos({ payload }) {
  try {
    const response = yield call(
      DataProjectApi.getProjectPhoto,
      payload.Type,
      payload.ProjectImage,
    );
    yield put(
      PhotosActions.getProjectPhotoSuccess({
        id: payload.id,
        Type: payload.Type,
        photo: URL.createObjectURL(response),
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* addProjectPhoto({ payload }) {
  try {
    yield call(
      DataProjectApi.uploadProjectPhoto,
      payload.id,
      payload.Type,
      payload.file,
    );
    yield put(
      PhotosActions.addProjectPhotoSuccess({
        id: payload.id,
        Type: payload.Type,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* uploadProjectPhoto({ payload }) {
  try {
    yield call(
      DataProjectApi.uploadProjectPhoto,
      payload.id,
      payload.Type,
      payload.file,
    );
    yield put(
      PhotosActions.uploadProjectPhotoSuccess({
        id: payload.id,
        Type: payload.Type,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* getExpensePhotos({ payload }) {
  try {
    const response = yield call(ExpenseApi.getExpensePhoto, payload.Image);
    yield put(
      PhotosActions.getExpensePhotoSuccess({
        id: payload.id,
        photo: URL.createObjectURL(response),
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* getLeavePhotos({ payload }) {
  try {
    const response = yield call(LeaveApplicationApi.getLeavePhoto, payload.Image);
    yield put(
      PhotosActions.getLeavePhotoSuccess({
        id: payload.id,
        photo: URL.createObjectURL(response),
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* addExpensePhoto({ payload }) {
  try {
    yield call(ExpenseApi.uploadExpensePhoto, payload.id, payload.file);
    yield put(
      PhotosActions.addExpensePhotoSuccess({
        id: payload.id,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}

export function* uploadExpensePhoto({ payload }) {
  try {
    yield call(ExpenseApi.uploadExpensePhoto, payload.id, payload.file);
    yield put(
      PhotosActions.uploadExpensePhotoSuccess({
        id: payload.id,
        photo: payload.photo,
      }),
    );
  } catch (e) {
    yield put(PhotosActions.photoFailure(e));
  }
}
