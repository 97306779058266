import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';

import { registerLicense } from '@syncfusion/ej2-base';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import store from 'Store';
import ToggleColorMode from 'Context/themeContext';
import stream from 'Services/stream';

import ErrorBoundary from './ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import 'Assets/css/app.css';
 
const root = createRoot(document.getElementById('root'));

registerLicense(stream.syncfusionLicense);

root.render(
  <ErrorBoundary>
    <ClearBrowserCacheBoundary auto fallback="Loading" duration={20000}>
      <Provider store={store}>
        <Router>
          <ToggleColorMode />
          <ToastContainer />
        </Router>
      </Provider>
    </ClearBrowserCacheBoundary>
  </ErrorBoundary>,
);

reportWebVitals();
