import { combineReducers } from 'redux';

import Admin from './Admin';
import BaseData from './BaseData';
import CompanySettings from './CompanySettings';
import Dashboard from './Dashboard';
import MTDB from './MTDB';
import Photos from './Photos';
import PManagement from './PManagement';
import ReportBuilder from './ReportBuilder';
import Reporting from './Reporting';
import Strategy from './Strategy';
import Template from './Template';
import Task from './Task';

export default combineReducers({
  Admin,
  BaseData,
  CompanySettings,
  Dashboard,
  MTDB,
  Photos,
  PManagement,
  ReportBuilder,
  Reporting,
  Strategy,
  Task,
  Template,
});
