import { call, put } from 'redux-saga/effects';

import { creators as DebtorsActions } from 'Reducers/Reporting/Debtors';
import ApiDebtors from 'Api/ApiDebtors';

export function* getDebtorsSummary({ payload }) {
  try {
    const response = yield call(ApiDebtors.getSummary, {
      selectedYears: payload.selectedYears,
      defaultYear: payload.defaultYear,
      defaultMonth: payload.defaultMonth,
    });
    yield put(DebtorsActions.debtorsSuccess({ summaryData: response }));
  } catch (e) {
    yield put(DebtorsActions.debtorsFailure(e));
  }
}

export function* getDebtorsDetail({ payload }) {
  try {
    const response = yield call(ApiDebtors.getDetail, {
      selectedYears: payload.selectedYears,
      defaultYear: payload.defaultYear,
      defaultMonth: payload.defaultMonth,
    });
    yield put(DebtorsActions.debtorsSuccess({ detailData: response }));
  } catch (e) {
    yield put(DebtorsActions.debtorsFailure(e));
  }
}
