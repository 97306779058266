import { call, put } from 'redux-saga/effects';

import { creators as OpportunityActions } from 'Reducers/Reporting/Opportunity';
import ApiOpportunity from 'Api/ApiOpportunity';

export function* getOpportunitySummary({ payload }) {
  try {
    const response = yield call(
      ApiOpportunity.getSummary,
      payload.selectedYears,
    );
    yield put(OpportunityActions.opportunitySuccess({ summaryData: response }));
  } catch (e) {
    yield put(OpportunityActions.opportunityFailure(e));
  }
}

export function* getOpportunityDetail({ payload }) {
  try {
    const response = yield call(
      ApiOpportunity.getDetail,
      payload.selectedYears,
    );
    yield put(OpportunityActions.opportunitySuccess({ detailData: response }));
  } catch (e) {
    yield put(OpportunityActions.opportunityFailure(e));
  }
}
