import { call, put } from 'redux-saga/effects';

import { creators as DefaultActions } from 'Reducers/Reporting/Default';
import ApiDefault from 'Api/ApiDefault';

export function* getDefaultInfo({ payload }) {
  try {
    const response = yield call(ApiDefault.getDefaultInfo, payload.OrgName);
    yield put(DefaultActions.defaultSuccess({ defaultInfo: response }));
  } catch (e) {
    yield put(DefaultActions.defaultFailure(e));
  }
}
