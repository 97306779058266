import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const ApiYearlyFinancialYTD = () => {
  const getSummary = async (selectedYears) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    const filter = { FYs: _selectedYears };

    queryParams += '?filter=' + JSON.stringify(filter);

    return await dispatchGetJSONAsync(
      `${API_ROOT}/yearlyfinancialperformanceYTD/${company_id}/summary${queryParams}`,
    );
  };

  const getDetail = async (selectedYears, defaultYear, defaultMonth) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    let filter = {
      FYs: _selectedYears,
      defaultYear: defaultYear,
      defaultMonth: defaultMonth,
    };

    queryParams += '?filter=' + JSON.stringify(filter);
    return await dispatchGetJSONAsync(
      `${API_ROOT}/yearlyfinancialperformanceYTD/${company_id}/detail${queryParams}`,
    );
  };

  return {
    getSummary,
    getDetail,
  };
};

export default ApiYearlyFinancialYTD();
