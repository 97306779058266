import { call, put } from 'redux-saga/effects';

import { creators as StrategyActions } from 'Reducers/Strategy';
import Api from 'Api/ApiStrategy';

export function* getPlanHeaders() {
  try {
    const response = yield call(Api.getStrategyPlanHeaders, null);
    yield put(StrategyActions.strategySuccess({ planheaders: response }));
  } catch (e) {
    yield put(StrategyActions.strategySuccess(e));
  }
}

export function* getPlanDetails({ payload }) {
  try {
    const response = yield call(Api.getStrategyPlanDetails, payload);
    yield put(StrategyActions.strategySuccess({ plandetails: response }));
  } catch (e) {
    yield put(StrategyActions.strategySuccess(e));
  }
}

export function* getPlanSummaries({ payload }) {
  try {
    const response = yield call(Api.getStrategyPlanSummaries, payload);
    yield put(StrategyActions.strategySuccess({ plansummaries: response }));
  } catch (e) {
    yield put(StrategyActions.strategySuccess(e));
  }
}
