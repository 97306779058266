import { call, put } from 'redux-saga/effects';

import { creators as CashFlowActions } from 'Reducers/Reporting/CashFlow';
import ApiCashFlow from 'Api/ApiCashFlow';

export function* getTopData({ payload }) {
  try {
    const response = yield call(ApiCashFlow.getTop, payload.selectedYears);
    yield put(CashFlowActions.cashflowSuccess({ topData: response }));
  } catch (e) {
    yield put(CashFlowActions.cashflowFailure(e));
  }
}

export function* getMiddleData({ payload }) {
  try {
    const response = yield call(ApiCashFlow.getMiddle, payload.dimDate);
    yield put(CashFlowActions.cashflowSuccess({ middleData: response }));
  } catch (e) {
    yield put(CashFlowActions.cashflowFailure(e));
  }
}

export function* getBottomData({ payload }) {
  try {
    const response = yield call(ApiCashFlow.getBottom, payload.selectedYears);
    yield put(CashFlowActions.cashflowSuccess({ bottomData: response }));
  } catch (e) {
    yield put(CashFlowActions.cashflowFailure(e));
  }
}
