import { call, put } from 'redux-saga/effects';

import { creators as YearlyFinancialYTDActions } from 'Reducers/Reporting/YearlyFinancialYTD';
import ApiYearlyFinancialYTD from 'Api/ApiYearlyFinancialYTD';

export function* getYearlyFinancialYTDSummary({ payload }) {
  try {
    const response = yield call(
      ApiYearlyFinancialYTD.getSummary,
      payload.selectedYears,
    );
    yield put(
      YearlyFinancialYTDActions.yearlyytdSuccess({ summaryData: response }),
    );
  } catch (e) {
    yield put(YearlyFinancialYTDActions.yearlyytdFailure(e));
  }
}

export function* getYearlyFinancialYTDDetail({ payload }) {
  try {
    const response = yield call(
      ApiYearlyFinancialYTD.getDetail,
      payload.selectedYears,
      payload.defaultYear,
      payload.defaultMonth,
    );
    yield put(
      YearlyFinancialYTDActions.yearlyytdSuccess({ detailData: response }),
    );
  } catch (e) {
    yield put(YearlyFinancialYTDActions.yearlyytdFailure(e));
  }
}
