import { call, put } from 'redux-saga/effects';

import { creators as WorkInHandActions } from 'Reducers/Reporting/WorkInHand';
import ApiWorkInHand from 'Api/ApiWorkInHand';

export function* getWorkInHandSummary({ payload }) {
  try {
    const response = yield call(ApiWorkInHand.getSummary, payload.dimDate);
    yield put(WorkInHandActions.workinhandSuccess({ summaryData: response }));
  } catch (e) {
    yield put(WorkInHandActions.workinhandFailure(e));
  }
}

export function* getWorkInHandDetail({ payload }) {
  try {
    const response = yield call(ApiWorkInHand.getDetail, payload.dimDate);
    yield put(WorkInHandActions.workinhandSuccess({ detailData: response }));
  } catch (e) {
    yield put(WorkInHandActions.workinhandFailure(e));
  }
}
