import { createActions, handleActions } from 'redux-actions';

const initState = {
  planheaders: null,
  plandetails: null,
  plansummaries: null,
  fetching: false,
  error: null,
};

export const creators = createActions({
  STRATEGY_GET_PLANHEADERS: (payload) => payload,
  STRATEGY_GET_PLANDETAILS: (payload) => payload,
  STRATEGY_GET_PLANSUMMARIES: (payload) => payload,
  STRATEGY_SUCCESS: (payload) => payload,
  STRATEGY_FAILURE: (payload) => payload,
});

const getPlanHeadersReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getPlanDetailsReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const getPlanSummariesReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    STRATEGY_GET_PLANHEADERS: getPlanHeadersReducer,
    STRATEGY_GET_PLANDETAILS: getPlanDetailsReducer,
    STRATEGY_GET_PLANSUMMARIES: getPlanSummariesReducer,
    STRATEGY_SUCCESS: successReducer,
    STRATEGY_FAILURE: failureReducer,
  },
  initState,
);
