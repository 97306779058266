import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const BalanceSheet = () => {
  const getQuerys = async (dimdate) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let queryjson = [];
    queryjson[0] = dimdate;
    let filter = { DimDateKey: queryjson };

    queryParams += '?filter=' + JSON.stringify(filter);
    return await dispatchGetJSONAsync(
      `${API_ROOT}/balancesheet/${company_id}/querys${queryParams}`,
    );
  };

  return {
    getQuerys,
  };
};

export default BalanceSheet();
