import React from 'react';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';

import { styles } from './style';

function Footer(props) {
  const { classes } = props;

  return <div className={classes.root}></div>;
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
