import { call, put } from 'redux-saga/effects';

import { creators as WorkGeneratedActions } from 'Reducers/Reporting/WorkGenerated';
import ApiWorkGenerated from 'Api/ApiWorkGenerated';

export function* getWorkGeneratedQuery({ payload }) {
  try {
    const response = yield call(
      ApiWorkGenerated.getQuery,
      payload.selectedYears,
    );
    yield put(
      WorkGeneratedActions.workgeneratedSuccess({ queryData: response }),
    );
  } catch (e) {
    yield put(WorkGeneratedActions.workgeneratedFailure(e));
  }
}
