import { call, put } from 'redux-saga/effects';

import { creators as ProjectPerformanceActions } from 'Reducers/Reporting/ProjectPerformance';
import ApiProjectPerformance from 'Api/ApiProjectPerformance';

export function* getProjectPerformanceSummary({ payload }) {
  try {
    const response = yield call(
      ApiProjectPerformance.getSummary,
      payload.selectedYear,
    );
    yield put(
      ProjectPerformanceActions.projectperformanceSuccess({
        summaryData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectPerformanceActions.projectperformanceFailure(e));
  }
}

export function* getProjectPerformanceDetail({ payload }) {
  try {
    const response = yield call(
      ApiProjectPerformance.getDetail,
      payload.selectedYear,
    );
    yield put(
      ProjectPerformanceActions.projectperformanceSuccess({
        detailData: response,
      }),
    );
  } catch (e) {
    yield put(ProjectPerformanceActions.projectperformanceFailure(e));
  }
}
