import React, { useContext, createContext, useState, useEffect } from 'react';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { InteractionType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';

import { getUser } from 'Api/GraphService';
import stream from 'Services/stream';

const ssoContext = createContext({
  user: undefined,
  accessToken: undefined,
  signIn: undefined,
  signOut: undefined,
  formatToken: undefined,
  authProvider: undefined,
});

export function useSsoContext() {
  return useContext(ssoContext);
}

export default function ProvideSsoContext({ children }) {
  const auth = useProvideSsoContext();
  return <ssoContext.Provider value={auth}>{children}</ssoContext.Provider>;
}

function useProvideSsoContext() {
  const msal = useMsal();
  const [user, setUser] = useState(undefined);
  const [accessToken, setAccessToken] = useState('');

  const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
    msal.instance,
    {
      account: msal.instance.getActiveAccount(),
      scopes: stream.ssoConfig.scopes,
      interactionType: InteractionType.Popup,
    },
  );

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        try {
          const account = msal.instance.getActiveAccount();
          if (account) {
            const user = await getUser(authProvider);

            setUser({
              displayName: user.displayName || '',
              email: user.mail || user.userPrincipalName || '',
              timeFormat: user.mailboxSettings?.timeFormat || 'h:mm a',
              timeZone: user.mailboxSettings?.timeZone || 'UTC',
            });
          }
        } catch (err) {
          toast.error(err.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
        }
      }
    };
    checkUser();
  });

  const signIn = async () => {
    await msal.instance
      .loginPopup({
        scopes: stream.ssoConfig.scopes,
        prompt: 'select_account',
      })
      .then(async (res) => {
        const accounts = msal.instance.getAllAccounts();
        if (accounts.length > 0) {
          let activeAccount = accounts.find(
            (acc) => acc.username === res.account.username,
          );
          msal.instance.setActiveAccount(activeAccount);
        }

        const user = await getUser(authProvider);
        setUser({
          displayName: user.displayName || '',
          email: user.mail || user.userPrincipalName || '',
          timeFormat: user.mailboxSettings?.timeFormat || '',
          timeZone: user.mailboxSettings?.timeZone || 'UTC',
        });

        if (window.location.pathname === '/home/accountspayable') {
          msal.instance
            .acquireTokenSilent({ scopes: stream.ssoConfig.scopes })
            .then((res) => {
              setAccessToken(res.accessToken);
            });
        }
      })
      .catch((error) => toast.error(error.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"}));
  };

  const signOut = async () => {
    await msal.instance.logoutPopup();
    setUser(undefined);
  };

  const formatToken = () => {
    setAccessToken('');
  };

  return {
    user,
    accessToken,
    signIn,
    signOut,
    formatToken,
    authProvider,
  };
}
