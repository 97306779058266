import { createActions, handleActions } from 'redux-actions';

const initState = {
  people: [],
  clients: [],
  projects: [],
};

export const creators = createActions({
  SET_BASE_DATA: (payload) => payload,
  SET_PEOPLE_DATA: (payload) => payload,
  SET_CLIENT_DATA: (payload) => payload,
  SET_PROJECT_DATA: (payload) => payload,
});

const setBaseDataReducer = (state, { payload }) => {
  return {
    ...state,
    people: payload.people,
    clients: payload.clients,
    projects: payload.projects,
  };
};

const setPeopleDataReducer = (state, { payload }) => {
  return {
    ...state,
    people: payload,
  };
};

const setClientDataReducer = (state, { payload }) => {
  return {
    ...state,
    clients: payload,
  };
};

const setProjectDataReducer = (state, { payload }) => {
  return {
    ...state,
    projects: payload,
  };
};

export default handleActions(
  {
    SET_BASE_DATA: setBaseDataReducer,
    SET_PEOPLE_DATA: setPeopleDataReducer,
    SET_CLIENT_DATA: setClientDataReducer,
    SET_PROJECT_DATA: setProjectDataReducer,
  },
  initState,
);
