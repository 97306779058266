export const API_ROOT = `https://${document.domain}/api`;
export const API_ASYNC_POLL_TIMEOUT = 500;
export const API_ASYNC_POLL_COUNT = 200;

function fetchAuthRetry(url, options = {}, attempts = 3) {
  return fetch(url, options).then((response) => {
    if (response.status !== 401 || attempts <= 0) return response;
    return fetchAuthRetry(url, options, attempts - 1);
  });
}

export async function dispatchGetJSONAsync(url) {
  try {
    let response = await fetchAuthRetry(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let task_data = await response.json();

    if (window.location.href !== `https://${document.domain}/login`) {
      if (task_data.error) {
        window.location.href = `https://${document.domain}/login`;
      } else {
        return task_data;
      }
    } else return task_data;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

export async function dispatchPostJSONAsync(url, json) {
  try {
    let response = await fetchAuthRetry(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });

    let task_data = await response.json();
    if (task_data) {
      if (window.location.href !== `https://${document.domain}/login`) {
        if (task_data.error) {
          window.location.href = `https://${document.domain}/login`;
        } else {
          return task_data;
        }
      } else return task_data;
    }
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

export async function dispatchFilePostJSONAsync(url, json) {
  try {
    let response = await fetchAuthRetry(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    });
    let task_data = await response.blob();

    if (window.location.href !== `https://${document.domain}/login`) {
      if (task_data.error)
        window.location.href = `https://${document.domain}/login`;
      else return task_data;
    } else return task_data;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}

export async function dispatchPostFormAsync(url, formData) {
  try {
    let response = await fetchAuthRetry(url, {
      method: 'POST',
      body: formData,
    });
    let task_data = await response.json();

    if (window.location.href !== `https://${document.domain}/login`) {
      if (task_data.error)
        window.location.href = `https://${document.domain}/login`;
      else return task_data;
    } else return task_data;
  } catch (error) {
    console.log('ERROR', error);
    return null;
  }
}
