import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
  dispatchPostFormAsync,
  dispatchFilePostJSONAsync,
} from './BaseApi';

async function getLeaveTypes() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get_types`;
  return await dispatchGetJSONAsync(url);
}

async function getAllLeave() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get_all`;
  return await dispatchGetJSONAsync(url);
}


async function getAllLeaveTypes() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get_all_types`;
  return await dispatchGetJSONAsync(url);
}

async function getUserLeaveApplication(user_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get_user_leave`;
  let leave = await dispatchPostJSONAsync(url, {user_id});
  return leave;
}

async function getLeaveApplicationData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get`;
  return await dispatchGetJSONAsync(url);
}

async function addEmptyLeaveApplicationData() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/add`;
  return await dispatchGetJSONAsync(url);
}

async function editLeaveApplicationData(leave_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/edit`;
  return await dispatchPostJSONAsync(url, leave_data);
}

async function getLeavePhoto(image_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/get_photo`;
  return await dispatchPostJSONAsync(url, { image_id });
}

async function uploadLeaveApplicationFile(id, DimPeopleKey, file) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/upload`;
  let data = new FormData();
  data.append('id', id);
  data.append('DimPeopleKey', DimPeopleKey);
  data.append('file', file);
  data.append('file_name', file.name);
  return await dispatchPostFormAsync(url, data);
}

async function removeLeaveApplicationFile(id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/remove`;
  return await dispatchPostJSONAsync(url, { id });
}

async function deleteLeaveApplicationData(id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, { id });
}

async function submitApprovalLeave(leave_ids) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/submit_approval`;
  return await dispatchPostJSONAsync(url, { leave_ids });
}

async function getResourceForecast() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/resource_forecast`;
  return await dispatchGetJSONAsync(url);
}

async function editLeaveApplicationHours(payload) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/leaveapplication/${company.id}/edit_hours`;
  return await dispatchPostJSONAsync(url, {
    ...payload,
  });
}

const LeaveApplicationApi = {
  getLeaveTypes,
  getAllLeaveTypes,
  getLeaveApplicationData,
  addEmptyLeaveApplicationData,
  editLeaveApplicationData,
  uploadLeaveApplicationFile,
  removeLeaveApplicationFile,
  deleteLeaveApplicationData,
  submitApprovalLeave,
  getResourceForecast,
  editLeaveApplicationHours,
  getAllLeave,
  getUserLeaveApplication, 
  getLeavePhoto,
};

export default LeaveApplicationApi;
