import React, { Component } from 'react';
import errorimg from 'Assets/image/error-page.jpg';

// const randomFactApi = 'https://api.api-ninjas.com/v1/facts?limit={1}'

// const requestOptions = {
//   method: 'GET',
//   headers: { 'X-Api-Key': 'xxUYq2cw5PnkbKwdhjpUQg==h4ng3xZEqnNo7YiA','Access-Control-Allow-Origin': '*' },
// };

// const callFactApi = async () => {
//   const response = await fetch(randomFactApi, requestOptions);
//   const jsonResponse = await response.json();
//   console.log(jsonResponse);
// };

// callFactApi();

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
          <div
              style={{
                backgroundImage: `url(${errorimg})`,
                backgroundColor: '#4eb6ed',
                position: "fixed",
                width: '100%',
                height: '100%',
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                zIndex: 1000003
              }}
            > 
          </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
