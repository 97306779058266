import { createActions, handleActions } from 'redux-actions';

const initState = {
  tasks: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  TASK_GET_TASKS: (payload) => payload,
  TASK_SUCCESS: (payload) => payload,
  TASK_FAILURE: (payload) => payload,
});

const getTasksReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    TASK_GET_TASKS: getTasksReducer,
    TASK_SUCCESS: successReducer,
    TASK_FAILURE: failureReducer,
  },
  initState,
);
