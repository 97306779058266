import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
} from './BaseApi';

//Admin
async function getAllCompanies() {
  const url = `${API_ROOT}/admin/companies`;
  return await dispatchGetJSONAsync(url);
}

async function getCompanyUsers(company_id) {
  const url = `${API_ROOT}/admin/company_users/${company_id}`;
  let users = await dispatchGetJSONAsync(url);
  users.data = users.data
    ? users.data.map((user) => {
        return { ...user, name: `${user.first_name} ${user.last_name}` };
      })
    : [];
  return users;
}

async function deleteUser(company_id, user_id) {
  const url = `${API_ROOT}/admin/delete_user`;
  return await dispatchPostJSONAsync(url, {
    company_id,
    user_id,
  });
}

async function lockUser(company_id, user_id) {
  const url = `${API_ROOT}/admin/lock_user`;
  return await dispatchPostJSONAsync(url, {
    company_id,
    user_id,
  });
}

async function unlockUser(company_id, user_id) {
  const url = `${API_ROOT}/admin/unlock_user`;
  return await dispatchPostJSONAsync(url, {
    company_id,
    user_id,
  });
}

async function resetUserPassword(user_id, new_password) {
  const url = `${API_ROOT}/admin/reset_user_password`;
  return await dispatchPostJSONAsync(url, {
    user_id,
    new_password,
  });
}

async function shadowUser(company_id, user_id) {
  const url = `${API_ROOT}/admin/shadow_user`;
  return await dispatchPostJSONAsync(url, {
    company_id,
    user_id,
  });
}

async function deleteCompany(company_id) {
  const url = `${API_ROOT}/admin/delete_company`;
  return await dispatchPostJSONAsync(url, {
    company_id,
  });
}

//Manage Temp DB
async function getTables() {
  const url = `${API_ROOT}/managetemplate/tables`;
  return await dispatchGetJSONAsync(url);
}

async function getTableCols(table_name) {
  const url = `${API_ROOT}/managetemplate/cols`;
  return await dispatchPostJSONAsync(url, {
    table_name,
  });
}

async function getTableRows(table_name) {
  const url = `${API_ROOT}/managetemplate/rows`;
  return await dispatchPostJSONAsync(url, {
    table_name,
  });
}

async function addTableRow(table_name) {
  const url = `${API_ROOT}/managetemplate/add_row`;
  return await dispatchPostJSONAsync(url, {
    table_name,
  });
}

async function deleteTableRow(table_name, row_id) {
  const url = `${API_ROOT}/managetemplate/delete_row`;
  return await dispatchPostJSONAsync(url, {
    table_name,
    row_id,
  });
}

// async function addTableCol(table_name, col_name, col_type) {
//   const url = `${API_ROOT}/managetemplate/add_col`;
//   return await dispatchPostJSONAsync(url, {
//     table_name,
//     col_name,
//     col_type,
//   });
// }

async function updateTableData(table_name, updated_data) {
  const url = `${API_ROOT}/managetemplate/update_data`;
  return await dispatchPostJSONAsync(url, {
    table_name,
    updated_data,
  });
}

async function getAdminEmailTemplate() {
  const url = `${API_ROOT}/admin/get_email_template`;
  return await dispatchGetJSONAsync(url);
}

async function updateAdminEmailTemplate(design, html, subject) {
  const url = `${API_ROOT}/admin/update_email_template`;
  return await dispatchPostJSONAsync(url, {
    design,
    html,
    subject,
  });
}

//Package Management
async function getPackageSettings() {
  const url = `${API_ROOT}/admin/get_package_settings`;
  return await dispatchPostJSONAsync(url);
}

async function getPackages() {
  const url = `${API_ROOT}/admin/get_package`;
  return await dispatchPostJSONAsync(url);
}

async function addPackageSetting(package_info) {
  const url = `${API_ROOT}/admin/add_package`;
  return await dispatchPostJSONAsync(url, package_info);
}

async function updatePackageSetting(updated_data) {
  const url = `${API_ROOT}/admin/update_package`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

async function deletePackage(delete_ids) {
  const url = `${API_ROOT}/admin/delete_package`;
  return await dispatchPostJSONAsync(url, {
    delete_ids,
  });
}

async function getUserPackage(user_id, company_id) {
  const url = `${API_ROOT}/admin/get_user_package`;
  return await dispatchPostJSONAsync(url, {
    user_id,
    company_id,
  });
}

async function getSelectedPackageUsers(package_id) {
  const url = `${API_ROOT}/admin/get_select_package_users`;
  return await dispatchPostJSONAsync(url, {
    package_id,
  });
}

async function userDoPayment(token) {
  const user_id = appdata.getItem('company').user_id;
  const url = `${API_ROOT}/admin/user_do_payment`;

  return await dispatchPostJSONAsync(url, {
    user_id,
    headers: { 'Content-Type': 'text/plain' },
  });
}

async function clickConfirmNoti(payload) {
  const url = `${API_ROOT}/admin/click_confirm_noti`;
  return await dispatchPostJSONAsync(url, payload);
}

async function checkReferralCode(payload) {
  const url = `${API_ROOT}/admin/check_referral_code`;
  return await dispatchPostJSONAsync(url, payload);
}

async function saveRating(payload) {
  const url = `${API_ROOT}/admin/save_rating`;
  return await dispatchPostJSONAsync(url, payload);
}

async function updateRoleAccess(payload) {
  const url = `${API_ROOT}/admin/role/update_access`;
  return await dispatchPostJSONAsync(url, payload);
}

const AdminApi = {
  getAllCompanies,
  getCompanyUsers,
  deleteUser,
  lockUser,
  unlockUser,
  resetUserPassword,
  shadowUser,

  deleteCompany,

  getTables,
  getTableCols,
  getTableRows,
  // addTableCol,
  addTableRow,
  updateTableData,
  deleteTableRow,
  getAdminEmailTemplate,
  updateAdminEmailTemplate,

  getPackageSettings,
  getPackages,
  addPackageSetting,
  updatePackageSetting,
  deletePackage,
  getUserPackage,
  getSelectedPackageUsers,

  userDoPayment,
  checkReferralCode,
  clickConfirmNoti,
  saveRating,
  updateRoleAccess,
};

export default AdminApi;
