import { call, put } from 'redux-saga/effects';
import { creators as PManagementActions } from 'Reducers/PManagement';
import { toast } from 'react-toastify';

import AdminApi from 'Api/AdminApi';

export function* getPackageSettings() {
  try {
    const response = yield call(AdminApi.getPackageSettings, null);

    if (response.status === 200)
      yield put(
        PManagementActions.pmanagementSuccess({
          packageSettings: response.data,
        }),
      );
    else toast.error(response.message, {position: toast.POSITION.TOP_RIGHT, autoClose: 3000 ,toastId: "error"});
  } catch (e) {
    yield put(PManagementActions.pmanagementFailure(e));
  }
}
