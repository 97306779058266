import { createActions, handleActions } from 'redux-actions';

const initState = {
  tables: [],
  fetching: false,
  error: null,
};

export const creators = createActions({
  MTDB_GET_TABLES: (payload) => payload,
  MTDB_SUCCESS: (payload) => payload,
  MTDB_FAILURE: (payload) => payload,
});

const getTablesReducer = (state) => {
  return { ...state, fetching: true, error: null };
};

const successReducer = (state, { payload }) => {
  const nextState = Object.assign({}, state, payload);
  nextState.fetching = false;
  return nextState;
};

const failureReducer = (state, { payload }) => {
  return { ...state, fetching: false, error: payload };
};

export default handleActions(
  {
    MTDB_GET_TABLES: getTablesReducer,
    MTDB_SUCCESS: successReducer,
    MTDB_FAILURE: failureReducer,
  },
  initState,
);
