import { takeLatest, all } from 'redux-saga/effects';

import { creators as AdminActions } from 'Reducers/Admin';
import { creators as CompanySettingsActions } from 'Reducers/CompanySettings';
import { creators as TemplateActions } from 'Reducers/Template';
import { creators as PManagementActions } from 'Reducers/PManagement';
import { creators as MTDBActions } from 'Reducers/MTDB';
import { creators as TaskActions } from 'Reducers/Task';
import { creators as PhotosActions } from 'Reducers/Photos';
import { creators as FeesActions } from 'Reducers/Reporting/Fees';
import { creators as FeesProjectionActions } from 'Reducers/Reporting/FeesProjection';
import { creators as DebtorsActions } from 'Reducers/Reporting/Debtors';
import { creators as FinancialYTDActions } from 'Reducers/Reporting/FinancialYTD';
import { creators as ProjectedFinancialPerformanceActions } from 'Reducers/Reporting/ProjectedFinancialPerformance';
import { creators as YearlyFinancialYTDActions } from 'Reducers/Reporting/YearlyFinancialYTD';
import { creators as BalanceSheetActions } from 'Reducers/Reporting/BalanceSheet';
import { creators as WorkInHandActions } from 'Reducers/Reporting/WorkInHand';
import { creators as ProjectPerformanceActions } from 'Reducers/Reporting/ProjectPerformance';
import { creators as ExpensesActions } from 'Reducers/Reporting/Expenses';
import { creators as SummaryActions } from 'Reducers/Reporting/Summary';
import { creators as CashFlowActions } from 'Reducers/Reporting/CashFlow';
import { creators as ProjectedCashFlowActions } from 'Reducers/Reporting/ProjectedCashFlow';
import { creators as WorkGeneratedActions } from 'Reducers/Reporting/WorkGenerated';
import { creators as DefaultActions } from 'Reducers/Reporting/Default';
import { creators as KPIActions } from 'Reducers/Reporting/KPI';
import { creators as PeopleActions } from 'Reducers/Reporting/People';
import { creators as OpportunityActions } from 'Reducers/Reporting/Opportunity';
import { creators as StrategyActions } from 'Reducers/Strategy';

import { getAllCompanies } from './AdminSaga';
import {
  getAllCompanySettings,
  getAllOrganisationSettings,
} from './CompanySettingsSaga';
import { getTemplates } from './TemplateSaga';
import { getPackageSettings } from './PManagementSaga';
import { getTables } from './MTDBSaga';
import { getTasks } from './TaskSaga';
import {
  getPeoplePhotos, //
  addPeoplePhoto,
  uploadPeoplePhoto,
  getClientPhotos,//
  addClientPhoto,
  uploadClientPhoto,
  getProjectPhotos,//
  addProjectPhoto,
  uploadProjectPhoto,
  getExpensePhotos,//
  getLeavePhotos,
  addExpensePhoto,
  uploadExpensePhoto,
  // getInvoicePDF,
} from './PhotosSaga';
import { getFeesSummary, getFeesDetail } from './ReportingSagas/FeesSaga';
import {
  getFeesProjectionSummary,
  getFeesProjectionDetail,
} from './ReportingSagas/FeesProjectionSaga';
import {
  getDebtorsSummary,
  getDebtorsDetail,
} from './ReportingSagas/DebtorsSaga';
import {
  getFinancialYTDSummary,
  getFinancialYTDDetail,
} from './ReportingSagas/FinancialYTDSaga';
import {
  getProjectedFinancialPerformanceSummary,
  getProjectedFinancialPerformanceDetail,
} from './ReportingSagas/ProjectedFinancialPerformanceSaga';
import {
  getYearlyFinancialYTDSummary,
  getYearlyFinancialYTDDetail,
} from './ReportingSagas/YearlyFinancialYTDSaga';
import { getBalanceSheetQuerys } from './ReportingSagas/BalanceSheetSaga';
import {
  getWorkInHandSummary,
  getWorkInHandDetail,
} from './ReportingSagas/WorkInHandSaga';
import {
  getProjectPerformanceSummary,
  getProjectPerformanceDetail,
} from './ReportingSagas/ProjectPerformanceSaga';
import {
  getExpensesSummary,
  getExpensesDetail,
} from './ReportingSagas/ExpensesSaga';
import { getSummary, setSummary } from './ReportingSagas/SummarySaga';
import {
  getTopData,
  getMiddleData,
  getBottomData,
} from './ReportingSagas/CashFlowSaga';
import {
  getPCFTopData,
  getPCFMiddleData,
  getPCFBottomData,
} from './ReportingSagas/ProjectedCashFlowSaga';
import { getDefaultInfo } from './ReportingSagas/DefaultSaga';
import { getWorkGeneratedQuery } from './ReportingSagas/WorkGeneratedSaga';
import { getKPISummary } from './ReportingSagas/KPISaga';
import { getPeopleSummary, getPeopleDetail } from './ReportingSagas/PeopleSaga';
import {
  getOpportunitySummary,
  getOpportunityDetail,
} from './ReportingSagas/OpportunitySaga';
import {
  getPlanHeaders,
  getPlanDetails,
  getPlanSummaries,
} from './StrategySaga';

export default function* root() {
  yield all([
    takeLatest(AdminActions.adminGetAllCompanies, getAllCompanies),
    takeLatest(
      CompanySettingsActions.companySettingsGetAllCompanySettings,
      getAllCompanySettings,
    ),
    takeLatest(
      CompanySettingsActions.organisationSettingsGetAllOrganisationSettings,
      getAllOrganisationSettings,
    ),
    takeLatest(TemplateActions.templateGetTemplates, getTemplates),
    takeLatest(
      PManagementActions.pmanagementGetPackageSettings,
      getPackageSettings,
    ),
    takeLatest(MTDBActions.mtdbGetTables, getTables),
    takeLatest(TaskActions.taskGetTasks, getTasks),

    takeLatest(PhotosActions.getPeoplePhotos, getPeoplePhotos),

    // takeLatest(PhotosActions.getInvoicePDF, getInvoicePDF),
    
    takeLatest(PhotosActions.addPeoplePhoto, addPeoplePhoto),
    takeLatest(PhotosActions.uploadPeoplePhoto, uploadPeoplePhoto),
    takeLatest(PhotosActions.getClientPhotos, getClientPhotos),
    takeLatest(PhotosActions.addClientPhoto, addClientPhoto),
    takeLatest(PhotosActions.uploadClientPhoto, uploadClientPhoto),
    takeLatest(PhotosActions.getProjectPhotos, getProjectPhotos),
    takeLatest(PhotosActions.addProjectPhoto, addProjectPhoto),
    takeLatest(PhotosActions.uploadProjectPhoto, uploadProjectPhoto),
    takeLatest(PhotosActions.getExpensePhotos, getExpensePhotos),
    takeLatest(PhotosActions.getLeavePhotos, getLeavePhotos),
    takeLatest(PhotosActions.addExpensePhoto, addExpensePhoto),
    takeLatest(PhotosActions.uploadExpensePhoto, uploadExpensePhoto),

    // ****************     Strategy Sagas     ******************** //

    takeLatest(StrategyActions.strategyGetPlanheaders, getPlanHeaders),
    takeLatest(StrategyActions.strategyGetPlandetails, getPlanDetails),
    takeLatest(StrategyActions.strategyGetPlansummaries, getPlanSummaries),

    // ****************     Reporting Sagas     ******************** //

    takeLatest(FeesActions.feesSummaryRequest, getFeesSummary),
    takeLatest(FeesActions.feesDetailRequest, getFeesDetail),
    takeLatest(
      FeesProjectionActions.feesprojectionSummaryRequest,
      getFeesProjectionSummary,
    ),
    takeLatest(
      FeesProjectionActions.feesprojectionDetailRequest,
      getFeesProjectionDetail,
    ),
    takeLatest(DebtorsActions.debtorsSummaryRequest, getDebtorsSummary),
    takeLatest(DebtorsActions.debtorsDetailRequest, getDebtorsDetail),
    takeLatest(FinancialYTDActions.fytdSummaryRequest, getFinancialYTDSummary),
    takeLatest(FinancialYTDActions.fytdDetailRequest, getFinancialYTDDetail),
    takeLatest(
      ProjectedFinancialPerformanceActions.prjfinancialperfSummaryRequest,
      getProjectedFinancialPerformanceSummary,
    ),
    takeLatest(
      ProjectedFinancialPerformanceActions.prjfinancialperfDetailRequest,
      getProjectedFinancialPerformanceDetail,
    ),
    takeLatest(
      YearlyFinancialYTDActions.yearlyytdSummaryRequest,
      getYearlyFinancialYTDSummary,
    ),
    takeLatest(
      YearlyFinancialYTDActions.yearlyytdDetailRequest,
      getYearlyFinancialYTDDetail,
    ),
    takeLatest(BalanceSheetActions.glbsQuerysRequest, getBalanceSheetQuerys),
    takeLatest(
      WorkInHandActions.workinhandSummaryRequest,
      getWorkInHandSummary,
    ),
    takeLatest(WorkInHandActions.workinhandDetailRequest, getWorkInHandDetail),
    takeLatest(
      ProjectPerformanceActions.projectperformanceSummaryRequest,
      getProjectPerformanceSummary,
    ),
    takeLatest(
      ProjectPerformanceActions.projectperformanceDetailRequest,
      getProjectPerformanceDetail,
    ),
    takeLatest(ExpensesActions.expensesSummaryRequest, getExpensesSummary),
    takeLatest(ExpensesActions.expensesDetailRequest, getExpensesDetail),
    takeLatest(SummaryActions.summaryGetRequest, getSummary),
    takeLatest(SummaryActions.summarySetRequest, setSummary),
    takeLatest(CashFlowActions.cashflowTopRequest, getTopData),
    takeLatest(CashFlowActions.cashflowMiddleRequest, getMiddleData),
    takeLatest(CashFlowActions.cashflowBottomRequest, getBottomData),
    takeLatest(
      ProjectedCashFlowActions.projectedcashflowTopRequest,
      getPCFTopData,
    ),
    takeLatest(
      ProjectedCashFlowActions.projectedcashflowMiddleRequest,
      getPCFMiddleData,
    ),
    takeLatest(
      ProjectedCashFlowActions.projectedcashflowBottomRequest,
      getPCFBottomData,
    ),
    takeLatest(DefaultActions.defaultGetRequest, getDefaultInfo),
    takeLatest(
      WorkGeneratedActions.workgeneratedQueryRequest,
      getWorkGeneratedQuery,
    ),
    takeLatest(KPIActions.kpiSummaryRequest, getKPISummary),
    takeLatest(PeopleActions.peopleSummaryRequest, getPeopleSummary),
    takeLatest(PeopleActions.peopleDetailRequest, getPeopleDetail),
    takeLatest(
      OpportunityActions.opportunitySummaryRequest,
      getOpportunitySummary,
    ),
    takeLatest(
      OpportunityActions.opportunityDetailRequest,
      getOpportunityDetail,
    ),
  ]);
}
