import { API_ROOT, dispatchGetJSONAsync } from './BaseApi';
import appdata from 'Services/appdata';

// Must be function instead of Object as Generators don't work with complex structure...I guess.
const CashFlow = () => {
  const getTop = async (selectedYears, DateKey) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    let _selectedDimDate = DateKey.slice();
    const filter = { FYs: _selectedYears, DateKey: _selectedDimDate };

    queryParams += '?filter=' + JSON.stringify(filter);

    return await dispatchGetJSONAsync(
      `${API_ROOT}/projectedcashflow/${company_id}/topdata${queryParams}`,
    );
  };

  const getMiddle = async (selectedYears, DateKey) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);

    let _selectedDimDate = DateKey.slice();
    const filter = { FYs: _selectedYears, DateKey: _selectedDimDate };

    queryParams += '?filter=' + JSON.stringify(filter);

    return await dispatchGetJSONAsync(
      `${API_ROOT}/projectedcashflow/${company_id}/middledata${queryParams}`,
    );
  };

  const getBottom = async (selectedYears) => {
    let queryParams = '';
    const company_id = appdata.getItem('company').id;

    let _selectedYears = selectedYears.slice();
    const allIndex = _selectedYears.indexOf('All');
    if (allIndex > -1) _selectedYears.splice(allIndex, 1);
    const filter = { FYs: _selectedYears };

    queryParams += '?filter=' + JSON.stringify(filter);

    return await dispatchGetJSONAsync(
      `${API_ROOT}/projectedcashflow/${company_id}/bottomdata${queryParams}`,
    );
  };
  return {
    getTop,
    getMiddle,
    getBottom,
  };
};

export default CashFlow();
