import { call, put } from 'redux-saga/effects';

import { creators as BalanceSheetActions } from 'Reducers/Reporting/BalanceSheet';
import ApiBalanceSheet from 'Api/ApiBalanceSheet';

export function* getBalanceSheetQuerys({ payload }) {
  try {
    const response = yield call(ApiBalanceSheet.getQuerys, payload.dimdate);
    yield put(BalanceSheetActions.glbsSuccess({ queryResult: response }));
  } catch (e) {
    yield put(BalanceSheetActions.glbsFailure(e));
  }
}
