import { call, put } from 'redux-saga/effects';

import { creators as FinancialYTDActions } from 'Reducers/Reporting/FinancialYTD';
import ApiFinancialYTD from 'Api/ApiFinancialYTD';

export function* getFinancialYTDSummary({ payload }) {
  try {
    const response = yield call(
      ApiFinancialYTD.getSummary,
      payload.selectedYears,
    );
    yield put(FinancialYTDActions.fytdSuccess({ summaryData: response }));
  } catch (e) {
    yield put(FinancialYTDActions.fytdFailure(e));
  }
}

export function* getFinancialYTDDetail({ payload }) {
  try {
    const response = yield call(
      ApiFinancialYTD.getDetail,
      payload.selectedYearMonth,
    );
    yield put(FinancialYTDActions.fytdSuccess({ detailData: response }));
  } catch (e) {
    yield put(FinancialYTDActions.fytdFailure(e));
  }
}
