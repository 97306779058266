import appdata from 'Services/appdata';
import {
  API_ROOT,
  dispatchGetJSONAsync,
  dispatchPostJSONAsync,
} from './BaseApi';

/*************************   Plan Headers    ****************************/

async function getStrategyPlanHeaders() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/planheader/${company.id}`;
  return await dispatchGetJSONAsync(url);
}

async function getStrategyEmployees() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/planheader/${company.id}/employees`;
  return await dispatchGetJSONAsync(url);
}

async function addStrategyPlanHeader() {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/planheader/${company.id}/add`;
  return await dispatchPostJSONAsync(url);
}

async function deleteStrategyPlanHeader(planheader_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/planheader/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    planheader_id,
  });
}

async function updateStrategyPlanHeader(updated_data) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/planheader/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
  });
}

/*************************   Plan Details    ****************************/

async function getStrategyPlanDetails(planheader_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/plandetail/${company.id}/${planheader_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addStrategyPlanDetail(interval_cnt, selectedPlanId) {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/plandetail/${company.id}/add`;
  return await dispatchPostJSONAsync(url, {
    interval_cnt,
    planheader_id: selectedPlanId,
    create_user: user.first_name + ' ' + user.last_name,
  });
}

async function deleteStrategyPlanDetail(description, selectedPlanId) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/plandetail/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    description,
    planheader_id: selectedPlanId,
  });
}

async function updateStrategyPlanDetail(
  updated_data,
  selectedPlanId,
  prevDesc,
  interval,
) {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/plandetail/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
    planheader_id: selectedPlanId,
    prev_description: prevDesc,
    interval,
    modify_user: user.first_name + ' ' + user.last_name,
  });
}

/*************************   Plan Summaries    ****************************/

async function getStrategyPlanSummaries(planheader_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/plansummary/${company.id}/${planheader_id}`;
  return await dispatchGetJSONAsync(url);
}

async function addStrategyPlanSummary(selectedPlanId) {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/plansummary/${company.id}/add`;
  return await dispatchPostJSONAsync(url, {
    planheader_id: selectedPlanId,
    create_user: user.first_name + ' ' + user.last_name,
  });
}

async function deleteStrategyPlanSummary(plansummary_id) {
  const company = appdata.getItem('company');
  const url = `${API_ROOT}/plansummary/${company.id}/delete`;
  return await dispatchPostJSONAsync(url, {
    plansummary_id,
  });
}

async function updateStrategyPlanSummary(updated_data) {
  const company = appdata.getItem('company');
  const user = appdata.getItem('user');
  const url = `${API_ROOT}/plansummary/${company.id}/update`;
  return await dispatchPostJSONAsync(url, {
    updated_data,
    modify_user: user.first_name + ' ' + user.last_name,
  });
}

const ApiStrategy = {
  getStrategyPlanHeaders,
  getStrategyEmployees,
  addStrategyPlanHeader,
  deleteStrategyPlanHeader,
  updateStrategyPlanHeader,
  getStrategyPlanSummaries,
  addStrategyPlanSummary,
  deleteStrategyPlanSummary,
  updateStrategyPlanSummary,
  getStrategyPlanDetails,
  addStrategyPlanDetail,
  deleteStrategyPlanDetail,
  updateStrategyPlanDetail,
};

export default ApiStrategy;
